<template>
  <div class="googleimagecontainer">
    <div
      class="loading_mask"
      v-if="loading"
      v-loading="loading"
      element-loading-text="loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      v-on:click.stop="doThis()"
    ></div>

    <div style="display: flex;width: 100%; justify-content: start; align-items: center;margin-left: 20px;">
        <el-select v-model="image_type_value" placeholder="Image type" @change="imageTypeChange"
            :clearable="select_clearable">
            <el-option v-for="item in imageTypes" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>

        <div style="display: flex; margin-left: 10px;">
            <div v-for="(item,index) in colors" :key="item" @click="changeColor(index)" style="margin-right: 5px;">
                <div :style="`background-color: ${item};`" class="color--unselect"
                    v-if="currentColor != item&&item!='#FFFFFF'">
                </div>
                <div :style="`background-color: ${item};`" class="color--unselect--white"
                    v-if="currentColor != item&&item=='#FFFFFF'">
                </div>
                <div :style="`background-color: ${item};`" class="color--select" v-if="currentColor == item"></div>
            </div>

        </div>
    </div>

    <div style="display: flex;width: 100%; justify-content: start; margin-top: 15px;margin-left: 20px;">
        <span style="font-size: 14px; color: #808191;">Results displayed have been modified for commercial use. </span>
        <span style="font-size: 14px;color:#15C39A;cursor: pointer;margin-left: 2px;"
            @click="showPrivate()">See more</span>
    </div>

    <div style="width: 100%;  height: 1px; background-color: #d8d8d8; margin-top: 10px;"></div>

    <div style="width: 80%; flex: 1; margin-bottom: 20px;" id="search_parent">
        <div id="test"></div>
    </div>

    <div style="position: absolute; bottom: 10px; left: 10px;  font-size: 12px; color: #808191; height: 20px; background-color: #ffffff;">Only use image that you have the right to post.</div>
  </div>
</template>


<script>
var test11;
var divE;
export default {
  props: {
    doneSelect: {
      type: Function,
      default: () => null
    }
  },
  data() {
    return {
      loading: false,
      loadiingType: 2,
      image_type_value: '',
      imageTypes: [{
          "value": "",
          "label": 'Any type'
      }, {
          "value": "clipart",
          "label": 'Clip art'
      }, {
          "value": "face",
          "label": 'Faces of people'
      }, {
          "value": "lineart",
          "label": 'Line drawings'
      }, {
          "value": "stock",
          "label": 'Stock photos'
      }, {
          "value": "photo",
          "label": 'Photographs'
      }, {
          "value": "animated",
          "label": 'Animated GIFs'
      }],
      colors: [
          "#FF8D87",
          "#676EF9",
          "#FF6B5B",
          "#FFCE00",
          "#D67A00",
          "#FF9300",
          "#D9D9D9",
          "#1AE4A8",
          "#00A4FC",
          "#010001",
          "#FFFFFF",
      ],
      colorStr: [
          'pink',
          'purple',
          'red',
          'yellow',
          'brown',
          'orange',
          'gray',
          'teal',
          'blue',
          'black',
          'white',
      ],
      currentColor: '#00FFFFFF',
      currentColorIndex: -1,
      select_clearable: false,
      lastSearchKey: '',
      firebaseObj: null,
      db: null,
      headerAuthToken: "",
      optSlideId: '',
    };
  },
  created() {
    this.loadGooleScript();
  },
  mounted() {
    this.initCallback();
  },
  methods: {
    //显示提示
    showPrivate() {
        window.open('https://support.google.com/drive/answer/179622?p=docs_image_search&hl=en&visit_id=637765802042272752-1731005835&rd=1');
    },
    slideAlert(msg, _type) {
        this.$message({
            message: msg,
            type: _type
        });
    },
    imageTypeChange() {
        let _this = this;
        this.lastSearchKey = test11.getInputQuery();
        document.getElementById("test").innerHTML = "";
        test11 = google.search.cse.element.render({
            div: "test",
            attributes: {
                disableWebSearch: true,
                image_type: _this.image_type_value,
                enableHistory: false,
                image_as_rights: 'cc_publicdomain cc_noncommercial cc_nonderived cc_sharealike',
                image_as_filetype: 'jgp png bmp',
                image_dominantcolor: _this.currentColorIndex == -1 ? "" : _this.colorStr[_this.currentColorIndex]
            },
            tag: 'search'
        });
        test11.execute(this.lastSearchKey);
    },
    changeColor(index) {
        if (this.currentColorIndex == index) {
            this.currentColorIndex = -1;
            this.currentColor = "#00FFFFFF";
        } else {
            this.currentColor = this.colors[index];
            this.currentColorIndex = index;
        }
        let _this = this;
        this.lastSearchKey = test11.getInputQuery();
        document.getElementById("test").innerHTML = "";
        test11 = google.search.cse.element.render({
            div: "test",
            attributes: {
                disableWebSearch: true,
                image_type: _this.image_type_value,
                image_as_filetype: 'jgp png bmp', //jpg, gif, png, bmp, svg, webp, ico, raw.
                enableHistory: false,
                image_as_rights: 'cc_publicdomain cc_noncommercial cc_nonderived cc_sharealike',
                image_dominantcolor: _this.currentColorIndex == -1 ? "" : _this.colorStr[_this.currentColorIndex]
            },
            tag: 'search'
        });
        test11.execute(this.lastSearchKey);
    },
    select(url) {
      console.log("select image", url);
      this.image_type_value = "";
      this.currentColorIndex = -1;
      this.currentColor = "#00FFFFFF";
      this.doneSelect(url);
    },
    insertImage(url) {
      console.log("insertImage", url);
    },
    doThis() {
      console.log("stop click");
    },

    initCallback() {
      const _this = this;
      const makeTwoPartCallback = () => {
        let urls;
        const readyCallback = (name, q, promos, results, resultsDiv) => {
          urls = [];
          for (const result of results) {
            urls.push(result["image"]);
          }
        };
        const renderedCallback = (name, q, promos, results) => {
          const removeEventListeners = element => {
            const clone = element.cloneNode(true);
            element.parentNode.replaceChild(clone, element);
            return clone;
          };
          for (let i = 0; i < results.length; ++i) {
            const element = removeEventListeners(results[i]);
            element.addEventListener("click", () => {
              console.log(urls[i].url);
              _this.select(urls[i].url);
            });
          }
        };

        const myInitCallback = function() {
          if (document.readyState == "complete") {
            // Document is ready when Search Element is initialized.
            // Render an element with both search box and search results in div with id 'test'.
            var divE = document.getElementById("test");
            divE.innerHTML = "";
            test11 = google.search.cse.element.render({
              div: "test",
              attributes: {
                disableWebSearch: true,
                image_type: _this.image_type_value,
                enableHistory: false,
                image_as_rights:
                  "cc_publicdomain, cc_noncommercial, cc_nonderived",
                image_dominantcolor:
                  _this.currentColorIndex == -1
                    ? ""
                    : _this.colorStr[_this.currentColorIndex]
              },
              tag: "search"
            });
            // google.search.cse.element.render({
            //   div: "image_result",
            //   attributes: {
            //     disableWebSearch: true,
            //     image_type: _this.image_type_value,
            //     image_dominantcolor:
            //       _this.currentColorIndex == -1
            //         ? ""
            //         : _this.colorStr[_this.currentColorIndex]
            //   },
            //   tag: "search"
            // });
            console.log("init call back 1", _this.image_type_value);
          } else {
            // Document is not ready yet, when Search Element is initialized.
            google.setOnLoadCallback(function() {
              // Render an element with both search box and search results in div with id 'test'.
              var divE = document.getElementById("test");
              divE.innerHTML = "";
              test11 = google.search.cse.element.render({
                div: "test",
                attributes: {
                  disableWebSearch: true,
                  enableHistory: false,
                  image_type: _this.image_type_value,
                  image_as_rights:
                    "cc_publicdomain, cc_noncommercial, cc_nonderived",
                  image_dominantcolor:
                    _this.currentColorIndex == -1
                      ? ""
                      : _this.colorStr[_this.currentColorIndex]
                },
                tag: "search"
              });

              // google.search.cse.element.render({
              //     div: "image_result",
              //     attributes: {
              //         disableWebSearch: true,
              //         image_type: _this.image_type_value,
              //     },
              //     tag: 'searchresults'
              // });
            }, true);
            console.log("init call back 2", _this.image_type_value);
          }
        };
        return {
          readyCallback,
          renderedCallback,
          myInitCallback
        };
      };
      const {
        readyCallback: imageResultsReadyCallback,
        renderedCallback: imageResultsRenderedCallback,
        myInitCallback: myInitializationCallback
      } = makeTwoPartCallback();
      window.__gcse ||
        (window.__gcse = {
          parsetags: "onload", // Defaults to 'onload'
          initializationCallback: myInitializationCallback,
          searchCallbacks: {
            image: {
              ready: imageResultsReadyCallback,
              rendered: imageResultsRenderedCallback
            }
          }
        });
    },

    loadGooleScript() {
      var gcse = document.createElement("script");
      gcse.type = "text/javascript";
      gcse.async = true;
      gcse.src = "https://www.google.com/cse/cse.js?cx=d29cfc91a5299e6d9";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(gcse, s);
    }
  }
};
</script>



<style scoped>
.googleimagecontainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.loading_mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 999;
  /*-moz-opacity: 0.1;*/
  /*filter: alpha(opacity=10);*/
  color: #15c39a;
}

.image_opt_area {
  display: flex;
  width: 80%;
  align-items: center;
  padding-left: 20px;
}

.colorsItem {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
}

.searchColor {
  height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 10px;
}
.color--select {
    width: 35px;
    height: 35px;
    border: 2px solid #15c39a;
}

.color--unselect {
    width: 35px;
    height: 35px;
}

.color--unselect--white {
    width: 35px;
    height: 35px;
    border: 1px solid #36425a;
}

.page {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

.an {
    -webkit-transform: rotate(360deg);
    animation: rotation 3s linear infinite;
    -moz-animation: rotation 3s linear infinite;
    -webkit-animation: rotation 3s linear infinite;
    -o-animation: rotation 3s linear infinite;
}

.loading_mask {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 1.0);
    flex-direction: column;
}

.image_opt_area {
    display: flex;
    width: 80%;
    align-items: center;
    padding-left: 20px;
}

.colorsItem {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
}

.searchColor {
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 10px;
}
</style>
