<template>
  <div v-show="syncUI.drawUrl" class="flex_c h100 w100 draw-url">
    <div class="draw-actions flex_j w100">
      <el-button-group class="draw-button-group">
        <el-button :type="syncUI.drawShow ? 'primary' : ''" icon="" @click="switchBgFn(true)">Slide</el-button>
        <el-button :type="!syncUI.drawShow ? 'primary' : ''" icon="" @click="switchBgFn(false)">Blank</el-button>
      </el-button-group>
      <div>
        <el-button slot="reference" icon="el-icon-delete" circle @click="showConfirm = true"></el-button>
        <el-button slot="reference" icon="el-icon-close" circle @click="endDraw(true)"></el-button>
      </div>
    </div>
    <!--
    <div class="draw-actions-left">
      <el-button-group class="draw-button-group">
        <el-button :type="syncUI.drawShow ? 'primary' : ''" icon="" @click="switchBgFn(true)">Slide</el-button>
        <el-button :type="!syncUI.drawShow ? 'primary' : ''" icon="" @click="switchBgFn(false)">Blank</el-button>
      </el-button-group>
    </div>
    <div class="draw-actions-right">
      <el-button slot="reference" icon="el-icon-delete" circle @click="showConfirm = true"></el-button>
      <el-button slot="reference" icon="el-icon-close" circle @click="endDraw"></el-button>
    </div>
    -->
    <img
      style="max-width: 100%; max-height: 82%"
      v-if="syncUI.drawUrl?.length > 20"
      ref="imgRef"
      @load="onPicLoaded"
      :src="`${Fn.hashToUrl(syncUI.drawUrl)}`"
      :class="{'draw-text': syncUI.drawText}"
      alt="" />
    <!--
    <iframe
      ref="drawRef"
      v-if="!switching"
      id="drawapp"
      :src="`http://127.0.0.1:3000/draw/#room=${session.sid},${drawkey}`"
      :style="drawStyle"
      style="margin: 0; border: 0"></iframe>
      -->
    <iframe
      ref="drawRef"
      v-if="!switching"
      id="drawapp"
      :src="`/draw/#room=${session.sid},${drawkey}`"
      :style="drawStyle"
      style="margin: 0; border: 0"></iframe>
    <div
      v-if="showConfirm"
      role="tooltip"
      id="el-popover-8795"
      aria-hidden="false"
      class="el-popover el-popper"
      tabindex="0"
      style="position: fixed; top: 50px; right: 6px; transform-origin: center top"
      x-placement="bottom">
      <!---->
      <div class="el-popconfirm">
        <p class="el-popconfirm__main">
          <i class="el-popconfirm__icon el-icon-question" style="color: rgb(255, 153, 0)"></i>
          This will clear the whole canvas. Are you sure?
        </p>
        <div class="el-popconfirm__action">
          <el-button type="text" @click="showConfirm = false">Cancel</el-button>
          <el-button type="text" size="small" :loading="loading" @click="clearAndEndDraw">OK</el-button>
        </div>
      </div>
      <div x-arrow="" class="popper__arrow" style="left: 349.5px"></div>
    </div>
  </div>
</template>
<style>
#drawapp {
  position: absolute;
}
.draw-text {
  visibility: hidden;
}
.draw-url {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background: #fff;
  /*background: rgb(0 0 0 / 40%);*/
}
.draw-actions {
  padding: 10px;
  box-shadow: 0 1px 3px #0003, 0 1px 1px #00000024, 0 2px 1px -1px #0000001f;
  background: #fff;
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
}
.draw-actions-left {
  z-index: 100;
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
}
.draw-actions-right {
  z-index: 100;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
.draw-button-group {
  margin-right: 10px;
}
</style>
<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
import {showLoading, hideLoading} from '../utils/loading'
export default {
  props: {
    sendCanvas: {type: Function},
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      switching: false,
      first: true,
      processi: 0,
      uuid: '',
      drawRatio: 1,
      inited: false,
      drawPPTOld: {},
      drawPPTNew: {},
      drawBlankOld: {},
      drawBlankNew: {},
      isDrawOk: false,
      drawKey: null,
      showConfirm: false,
      imgPos: {width: 0, height: 0, x: 0, y: 0},
      one: {},
      interval: null,
    }
  },
  computed: {
    ...mapGetters(['getPage', 'getPageId', 'getQuestion']),
    ...mapGetters('student', ['currentPageAnswerList']),
    ...mapState(['syncUI', 'session']),
    drawStyle() {
      return `border: 1px dashed ${this.syncUI.drawShow ? 'transparent' : '#ccc'};width:${this.imgPos.width + 1}px;height:${this.imgPos.height + 1}px;top:${
        this.imgPos.y - 1.5
      }px;left:${this.imgPos.x - 1.5}px;`
    },
  },
  async created() {
    this.drawkey = this.session.drawkey || (await Fn.drawKey())
    if (this.session.drawkey !== this.drawkey) await this.$store.dispatch('upSession', {drawkey: this.drawkey})
  },
  mounted() {
    console.log('<=================mounted====>')
    showLoading()
    window.addEventListener('resize', this.getDimensions)
    window.addEventListener('message', this.onMessage)
    //this.interval = setInterval(this.process, 100)
  },
  destroyed() {
    this.doDestroy()
  },
  beforeDestroy() {
    this.doDestroy()
  },
  watch: {
    'syncUI.drawShow': {
      async handler(o) {
        if (!this.syncUI.drawText) {
          showLoading()
          // 切换并恢复回老的数据
          const viewBackgroundColor = o ? 'transparent' : '#ffffff'
          let target = o ? this.drawPPTOld : this.drawBlankOld
          console.log(o, viewBackgroundColor, target, '<==switchTab')
          // this.switching = true
          // await sleep(300)
          // this.switching = false
          // await sleep(1000)
          target = target ?? {}
          const appState = {...target.appState, viewBackgroundColor, zoom: {value: this.drawRatio}, pageId: this.uuid}
          this.postMessage({app: 'draw', get: 'info', elements: target?.elements ?? [], appState})
          hideLoading()
        }
      },
    },
  },
  methods: {
    ...mapActions(['setSyncUI']),
    async postMessage(data) {
      if (!this.$refs?.drawRef?.contentWindow) {
        await sleep(100)
        return this.postMessage(data)
      }
      return this.$refs.drawRef.contentWindow.postMessage(data, '*')
    },
    clearArtboard() {
      //this.postMessage({app: 'draw', get: 'info', elements: [], appState: {offsetLeft: 0, offsetTop: 0, scrollX: 0, scrollY: 0, zoom: {value: this.drawRatio}}})
      this.postMessage({app: 'draw', get: 'info', elements: [], appState: {zoom: {value: this.drawRatio}}})
    },
    resetArtboard() {
      this.postMessage({app: 'draw', get: 'info', appState: {offsetLeft: 0, offsetTop: 0, scrollX: 0, scrollY: 0, zoom: {value: this.drawRatio}}})
    },
    splitContent(content, length) {
      const contentArr = content.split(' ')
      const paragraph = []
      let line = ''

      contentArr.forEach((word) => {
        line += word + ' '
        if (line.length > length) {
          paragraph.push(line)
          line = ''
        }
      })

      paragraph.push(line)

      return paragraph
    },
    loadText() {
      const answer = this.currentPageAnswerList.filter((el) => {
        return el._id == this.syncUI.drawText
      })
      const fontSize = 20

      if (answer.length && answer[0].content) {
        const content = this.splitContent(answer[0].content, 45)
        const elements = [
          {
            angle: 0,
            backgroundColor: 'transparent',
            baseline: fontSize * (content.length + 2) * 2,
            boundElements: null,
            containerId: null,
            fillStyle: 'hachure',
            fontFamily: 2,
            fontSize: fontSize,
            groupIds: [],
            height: fontSize * (content.length + 2) * 2,
            id: answer[0]._id,
            isDeleted: false,
            link: null,
            locked: false,
            opacity: 100,
            originalText: content.join('\n'),
            roughness: 1,
            roundness: null,
            seed: 1701740877,
            strokeColor: '#000000',
            strokeStyle: 'solid',
            strokeWidth: 1,
            text: content.join('\n'),
            updated: 1673078723563,
            version: 1,
            versionNonce: 870762009,
            textAlign: 'left',
            type: 'text',
            verticalAlign: 'top',
            width: 1200,
            x: 350,
            y: 160,
          },
        ]
        this.postMessage({
          app: 'draw',
          get: 'info',
          elements: elements,
          nickname: currentNickname,
          appState: {viewBackgroundColor: '#ffffff', gridSize: null, scrollX: 0, scrollY: 0, zoom: {value: this.drawRatio}},
        })
      }
    },
    loadSlide() {
      let target = this.syncUI.drawShow ? this.drawPPTOld : this.drawBlankOld
      target = target ?? {}
      const viewBackgroundColor = this.syncUI.drawShow ? 'transparent' : '#ffffff'
      const appState = {...target?.appState, viewBackgroundColor, zoom: {value: this.drawRatio}, pageId: this.uuid}
      this.postMessage({app: 'draw', get: 'info', nickname: currentNickname, elements: target?.elements ?? [], appState})

      /*
      this.postMessage({
        app: 'draw',
        get: 'info',
        elements: this.syncUI.drawShow ? this.drawPPTOld.elements : this.drawBlankOld.elements,
        nickname: currentNickname,
        appState: {
          viewBackgroundColor: this.syncUI.drawShow ? 'transparent' : '#ffffff',
          gridSize: null,
          scrollX: 0,
          scrollY: 0,
          zoom: {value: this.drawRatio},
          pageId: this.uuid,
        },
      })*/
    },
    async switchBgFn(drawShow) {
      await this.saveData()
      this.setSyncUI({drawShow})
    },
    onMessage({data}, a) {
      if (data?.app !== 'draw') return
      if (data.ok) {
        this.isDrawOk = true
      }
      if (!this.syncUI.drawText) {
        if (data?.body?.appState.pageId !== this.uuid) {
          console.warn(data?.body?.appState.pageId, this.uuid, '<=====page id mismatch====>')
          //this.updateCanvas()
          hideLoading()
        } else {
          console.warn(this.inited, data?.body?.appState.pageId, this.uuid, '<=====page id matched====>')
        }

        if (!this.switching && this.inited && data?.body?.appState?.pageId == this.uuid) {
          hideLoading()
          this.processi = 0
          const newData = {
            type: 'excalidraw',
            appState: {
              zoom: data.body.appState.zoom ?? {value: 1},
              viewBackgroundColor: 'transparent',
              pageId: data.body.appState.pageId,
            },
            elements: data.body.elements,
            files: data.body.files,
          }

          if (this.syncUI.drawShow) {
            this.drawPPTNew = newData
          } else {
            this.drawBlankNew = newData
          }
          //this.base64Str = data.src

          console.log('<====new drawing data arrived====>')
        }
      }
    },
    doDestroy() {
      console.log('<===========doDestroy')
      hideLoading()
      this.drawRatio = 0
      this.isDrawOk = false
      this.drawKey = null
      this.imgPos = {width: 0, height: 0, x: 0, y: 0}
      window.removeEventListener('message', this.onMessage)
      window.removeEventListener('resize', this.getDimensions)
      if (this.interval) clearInterval(this.interval)
    },
    async clearAndEndDraw() {
      this.loading = true
      await this.clearCanvas()
      this.endDraw(false)
    },
    async endDraw(save) {
      if (save) {
        await this.saveData()
      }
      this.setSyncUI({drawUrl: '', drawText: ''})
    },
    async clearCanvas(id) {
      this.postMessage({
        app: 'draw',
        get: 'info',
        elements: [],
        appState: {viewBackgroundColor: 'transparent', zoom: {value: this.drawRatio}, pageId: id},
      })
      if (this.one._id) {
        await App.service('session-draw').patch(this.one._id, {ppt: {}, blank: {}})
      }
      console.log(id, '<================clearCanvas')
    },
    updateCanvas() {
      console.log('<================updateCanvas')
      if (this.one._id && this.drawPPTOld) {
        this.postMessage({
          app: 'draw',
          get: 'info',
          elements: this.drawPPTOld.elements,
          appState: {...this.drawPPTOld.appState, zoom: {value: this.drawRatio}},
        })
      } else {
        this.clearCanvas(this.uuid)
      }
    },
    async initScene() {
      if (!this.isDrawOk || !this.drawRatio) {
        await sleep(50)
        return this.initScene()
      }

      this.uuid = this.$route.params.id + this.getPageId
      console.log(this.uuid, '<===uuid======initScene')

      if (this.syncUI.drawText) {
        this.loadText()
      } else {
        await this.getDrawData()
        this.loadSlide()
      }

      hideLoading()
      // await sleep(800)
      // this.updateRatio(this.drawRatio)
      this.inited = true
    },
    async getDrawData() {
      //todo: get data from server
      //if (!this.one._id) {
      const rs = await App.service('session-draw').get('byPageId', {query: {sid: this.session.sid, pageId: this.getPageId}})
      this.one = rs
      this.drawPPTOld = rs.ppt
      this.drawBlankOld = rs.blank
      console.log(this.one, '<==============getDrawData')
      /*
        return
        //todo: save data to server
        const rs = await App.service('response').get(this.one._id)
        if (rs?.json) {
          this.drawPPTOld = rs.json
          if (!this.drawPPTOld.appState.pageId || this.drawPPTOld.appState.pageId.indexOf(this.$route.params.id) == -1) {
            this.drawPPTOld.appState.pageId = this.uuid
          }
          console.log(this.drawPPTOld, '<====getDrawData====')
        }
      //} else {
       // console.warn('<====never pained, frist visit====>')
      //}
      */
    },
    async process() {
      let needPatch = false

      const targetNew = this.syncUI.drawShow ? this.drawPPTNew : this.drawBlankNew
      const targetOld = this.syncUI.drawShow ? this.drawPPTOld : this.drawBlankOld

      if (this.processi > 6 && this.inited && targetNew?.appState?.pageId == this.uuid) {
        if (targetNew && JSON.stringify(targetOld) !== JSON.stringify(targetNew)) {
          if (!(!targetNew.elements.length && !this.one._id)) {
            //
            needPatch = true
          } else {
            console.log('<====waiting for response...====')
          }
        }
        this.processi = 0

        console.log(needPatch, '<====need patch or not====')
      }
      if (needPatch) {
        if (this.syncUI.drawShow) {
          this.drawPPTOld = {...this.drawPPTNew}
          await App.service('session-draw').patch(this.one._id, {ppt: this.drawPPTNew})
        } else {
          this.drawBlankOld = {...this.drawBlankNew}
          await App.service('session-draw').patch(this.one._id, {blank: this.drawBlankNew})
        }

        console.log(needPatch, '<====patched====')
      } else {
        this.processi++
      }
    },
    async saveData() {
      let needPatch = false

      const targetNew = this.syncUI.drawShow ? this.drawPPTNew : this.drawBlankNew
      const targetOld = this.syncUI.drawShow ? this.drawPPTOld : this.drawBlankOld

      if (targetNew && JSON.stringify(targetOld) !== JSON.stringify(targetNew)) {
        if (!(!targetNew.elements?.length && !this.one._id)) {
          needPatch = true
        } else {
          console.log('<====waiting for response...====')
        }
      }

      console.log(needPatch, '<====need patch or not====')
      if (needPatch) {
        if (this.syncUI.drawShow) {
          this.drawPPTOld = {...this.drawPPTNew}
          await App.service('session-draw').patch(this.one._id, {ppt: this.drawPPTNew})
        } else {
          this.drawBlankOld = {...this.drawBlankNew}
          await App.service('session-draw').patch(this.one._id, {blank: this.drawBlankNew})
        }

        console.log(needPatch, '<====patched====')
      }
    },
    async onPicLoaded() {
      if (!this.getDimensions()) {
        await sleep(500)
        return this.onPicLoaded()
      }
      this.initScene()
    },
    updateRatio(ratio) {
      this.postMessage({
        app: 'draw',
        appState: {
          viewBackgroundColor: !this.syncUI.drawShow ? '#ffffff' : 'transparent',
          gridSize: null,
          scrollX: 0,
          scrollY: 0,
          zoom: {value: ratio},
        },
      })
    },
    getImgRatio() {
      var image = new Image()
      image.src = Fn.hashToUrl(this.syncUI.drawUrl)
      image.onload = () => {
        const rs = this.$refs.imgRef?.width / image.width
        console.warn('drawRatio', rs)
        if (this.isDrawOk && this.drawRatio !== rs) {
          //if (this.isDrawOk) {
          this.updateRatio(rs)
        }
        this.drawRatio = rs
      }
    },
    getDimensions() {
      if (!this.$refs.imgRef) return console.warn('img no load'), false
      this.imgPos.x = this.$refs.imgRef?.offsetLeft
      this.imgPos.y = this.$refs.imgRef?.offsetTop
      this.imgPos.width = this.$refs.imgRef?.width
      this.imgPos.height = this.$refs.imgRef?.height
      this.getImgRatio()
      return true
    },
  },
}
</script>
