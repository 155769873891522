<template>
  <div class="flex_c h100 w100" style="position: fixed; z-index: 4047; top: 0; left: 0; background: #000">
    <div style="z-index: 100; position: absolute; right: 0.5rem; top: 0.5rem">
      <el-button icon="el-icon-close" circle @click="closeBtn"></el-button>
    </div>
    <iframe ref="taskRef" id="accessTask" :src="taskUrl" style="width: 100%; height: 100%; margin: 0; border: 0"></iframe>
  </div>
</template>

<style scoped></style>

<script>
import {mapState} from 'vuex'
import {showLoading, hideLoading, showToast} from '../../utils/loading'
export default {
  props: {
    addPPTItem: {
      type: Function,
    },
    closeBtn: {
      type: Function,
    },
    currentPage: {type: Number, default: 1},
  },
  computed: {
    ...mapState(['session']),
  },
  data() {
    return {
      taskUrl: null,
      domain: null,
    }
  },
  beforeDestroy() {
    window.removeEventListener('message', this.onMessage)
  },
  created() {
    const part = this.session.task?.service?.participants == 'educators' ? 'educators' : 'students'
    const isDev = /(dev|localhost|127\.0\.0\.1|192\.168\.|10\.0\.)/.test(location.hostname)
    const isTest = /(testroom)/.test(location.hostname)
    this.domain = isDev ? 'https://dev.classcipe.com' : isTest ? 'https://test.classcipe.com' : 'https://classcipe.com'
    this.taskUrl = `${this.domain}/v2/com/prompt/${part}?sid=${this.session._id}`
    //this.taskUrl = `http://localhost:9003/v2/com/prompt/${part}?sid=${this.session._id}`
    window.addEventListener('message', this.onMessage)
  },
  methods: {
    async onMessage({data}, a) {
      if (data?.app == 'web') {
        if (data.action == 'confirm') {
          showLoading()
          const page = {pic: data.pic}
          try {
            if (page.pic?.indexOf('http') == -1) {
              const file = await App.service('files').get(page.pic)
              if (file?.size) {
                page.size = file.size
              }
            }
          } catch (e) {}
          const query = {_id: this.session._id, index: this.currentPage, page}

          if (data.type) {
            data.type = data.type == 'multimedia' ? 'media' : data.type
            query.questions = [
              {
                type: data.type,
                multi: false,
                scoreEnable: false,
                tips: data.tips ?? '',
              },
            ]

            if (data.type == 'website') {
              query.questions[0].data = `${this.domain}${data.url}`
            }
          }
          if (data.package) {
            const rs = await App.service('service-pack').get(data.package)
            if (rs.attachments?.length) {
              query.materialsList = []
              rs.attachments.forEach((e) => {
                query.materialsList.push({_id: e._id, type: e.mime.split('/')[0], desc: e.filename, key: e.hash, url: '', size: 0})
              })
            }
          }
          const rs = await App.service('session').patch('newPrompt', query)
          this.addPPTItem(rs)
          this.closeBtn()
        } else if (data.action == 'cancel') {
          this.closeBtn()
        }
      }
    },
  },
}
</script>
