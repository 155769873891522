<template>
  <div class="flex1" style="width: 100%; height: 100%">
    <pptDetail v-if="!showResponse" :teacher="true" :showLogo="true" />
    <div v-else-if="getQuestion" class="flex1">
      <teacherIndexItem :flag="false" :currentAnswerCount="currentAnswerCount" :textList="responseContentList" :flag_1="false" />
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import pptDetail from '../pptDetail.vue'
import teacherIndexItem from './Index.vue'
export default {
  components: {teacherIndexItem, pptDetail},
  props: {
    currentAnswerCount: {
      type: Number,
      default: 0,
    },
    responseContentList: {
      type: Array,
      function() {
        return []
      },
    },
  },
  computed: {
    ...mapGetters(['getQuestion']),
    ...mapState({
      currentPageIndex: (state) => state.student.currentPageIndex,
    }),
    showResponse() {
      return this.$store.getters['teacher/isResponseShow'](this.currentPageIndex)
    },
  },
  created() {},
  data() {
    return {
      height: window.winHeight,
    }
  },
}
</script>
