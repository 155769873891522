<!--用户底部的控制面板-->

<template>
  <div :class="`panel ${isDashboard ? 'dash-control' : ''}`">
    <template>
      <button class="control-bar__button" @click="lastPage()">
        <div class="control-bar__icon left"></div>
      </button>
      <div>
        <b>{{ currentPageIndex + 1 }}/{{ getPageNum }}</b>
      </div>
      <button class="control-bar__button" @click="nextPage()">
        <div class="control-bar__icon right"></div>
      </button>
    </template>
    <!--
    <template v-if="isDashboard">
      <div :class="dashTipsModalVisiable ? 'button_area back_focus' : 'button_area'">
        <div class="meterialimage" @click="showDashTips">
          <div class="fullbgimg dash-tip"></div>
        </div>
      </div>
    </template>
    -->
    <UploadEnter />

    <div :class="isClosed ? 'info_area' : 'info_area'">
      <div class="with-outer" v-if="!isDashboard">
        <i :class="`icon-circle ${current_response === 0 && 'red-icon'}`"></i>
        <strong>{{ current_response == 0 ? 'No' : current_response }} Response</strong>
      </div>

      <div class="button_area" :class="{buttondisable: cantStopStudentPaced()}" v-if="session.status === 'student'" @click="closeStudentPaced()">
        <i class="el-icon-close icon2"></i>
        <strong class="button_text">Stop Student-Paced</strong>
      </div>

      <div class="with-outer" v-else>
        <i :class="`icon-circle ${!isClosed && 'green-icon'}`"></i>
        <strong>{{ statusList[session.status] }}</strong>
      </div>
    </div>
    <template v-if="getQuestion && getQuestion.type !== 'website' && !['student', 'close'].includes(session.status)">
      <div :class="isLockPage ? 'button_area back_focus' : 'button_area'" @click="dolockPage()">
        <i class="icon2" :class="isLockPage ? 'el-icon-lock' : 'el-icon-unlock'"></i>
        <strong class="button_text">{{ isLockPage ? 'Unlock ' : 'Lock ' }} Screens</strong>
      </div>

      <div class="button_area" :class="{back_focus: isResponseShow}" @click="showRes">
        <div class="meterialimage">
          <i class="el-icon-s-platform icon2"></i>
        </div>
        <strong class="button_text">{{ isResponseShow ? 'Hide' : 'Show' }} Response(s)</strong>
      </div>
    </template>
    <!--material-->
    <div :class="`${isMaterialShow ? 'button_area back_focus' : 'button_area'}`" @click="changeMeterial">
      <div class="meterialimage">
        <i class="fa icon2" :class="isMaterialShow ? 'fa-eye' : 'fa-eye-slash'" aria-hidden="true"></i>
        <!-- <div :class="`fullbgimg ${isMaterialShow ? 'me-show' : 'me-hide'}`"></div> -->
        <i v-if="!Acan.isEmpty(getMaterial)" class="metarialnums">{{ getMaterial.length }}</i>
      </div>
      <strong class="button_text">{{ isMaterialShow ? 'Hide Material(s)' : 'Display Material(s)' }}</strong>
    </div>
    <div class="button_area" @click="showNewPrompt" v-if="session.status == 'live'">
      <div class="meterialimage">
        <i class="fa icon2 el-icon-news" aria-hidden="true"></i>
      </div>
      <strong class="button_text">New Prompt</strong>
    </div>
  </div>
</template>
<script>
import UploadEnter from '@/components/uploadFile/uploadEnter.vue'
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
export default {
  props: {
    currentPage: {type: Number, default: 1},
    isClosed: {type: Boolean, default: false},
    current_response: {type: Number, default: 0},
    turnModel: {type: Function},
    isDashboard: {type: Boolean, default: false},
    dashTipsModalVisiable: {type: Boolean, default: false},
    changePage: {type: Function},
    showNewPrompt: {type: Function},
    showDashTips: {type: Function},
  },
  computed: {
    ...mapState(['session', 'countDown']),
    ...mapGetters(['getQuestion', 'getMaterial', 'isMaterialShow', 'getPageId', 'getPageNum']),
    ...mapState({
      currentPageIndex: (state) => state.student.currentPageIndex,
    }),
    isResponseShow() {
      return this.$store.getters['teacher/isResponseShow'](this.currentPageIndex)
    },
    isLockPage() {
      return this.$store.getters['teacher/isLocked'](this.currentPageIndex)
    },
  },
  components: {UploadEnter},
  data() {
    return {
      dialogVisible: false,
      countDownStatus: 0,
      statusList: {close: 'Closed', 'insturctor-paced': 'Teacher-Paced', live: 'Teacher-Paced', student: 'Student-paced'},
    }
  },
  mounted() {
    if (window.countDownItCP) clearInterval(window.countDownItCP)
    window.countDownItCP = setInterval(() => {
      //this.countDownStatus = this.countDown.end === null ? 0 : Date.now() > this.countDown.end ? 2 : 1
      this.countDownStatus = this.session.countdown?.deadline == null ? 0 : Date.now() > new Date(this.session.countdown.deadline).getTime() ? 2 : 1
      if (this.countDownStatus === 2) this.showCountDown = true
      this.$forceUpdate()
    }, 1000)
  },
  methods: {
    ...mapActions('teacher', ['showResponse', 'showMaterial']),
    changeMeterial() {
      if (this.isResponseShow) {
        this.showResponse([this.currentPageIndex, this.getPageId])
      }
      this.showMaterial([this.currentPageIndex, this.getPageId])
    },
    async lockPage() {
      await this.$store.dispatch('teacher/lockPageBtn', this.currentPageIndex)
      this.$forceUpdate()
    },
    lastPage() {
      if (this.currentPage > 1) {
        let page = this.currentPage - 1
        this.changePage(page)
      }
    },
    nextPage() {
      if (this.currentPage < this.getPageNum) {
        let page = this.currentPage + 1
        this.changePage(page)
      }
    },
    cantStopStudentPaced() {
      //return this.countDownStatus || this.session.countdown?.studentCtrl ? true : false
      return this.countDownStatus || (this.session.countdown.type == 2 && this.session.sessionType == 'student') ? true : false
    },
    closeStudentPaced() {
      if (this.cantStopStudentPaced()) {
        this.$message.info('Student-pace cannot be stopped under Countdown/Deadline mode.')
      } else {
        this.turnModel()
      }
    },
    showRes() {
      if (this.isMaterialShow) {
        this.showMaterial([this.currentPageIndex, this.getPageId])
      }
      this.showResponse([this.currentPageIndex, this.getPageId])
      this.$forceUpdate()
    },
    dolockPage() {
      this.lockPage()
    },
  },
}
</script>
<style scoped>
strong {
  color: #36425a;
  font-size: 14px;
}
.panel {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #d9dfe4;
  box-sizing: border-box;
  padding: 0 0.5rem;
}
.svg_right {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.control-bar__button,
.control-bar__button--large,
.control-bar__button--lock {
  display: block;
  position: relative;
  height: auto;
  padding: 0.5em;
  background-color: transparent;
  border: 0px solid transparent;
  opacity: 1;
  text-align: center;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  cursor: pointer;
}
.control-bar__button {
  padding: 0.5rem 1rem;
}
.control-bar__icon {
  width: 13.34px;
  height: 24px;
  background-size: contain;
  background-position: 0 0;
  background-repeat: no-repeat;
}
.control-bar__icon.left {
  background-image: url(../../assets/picture/arrow-r.png);
}
.control-bar__icon.right {
  background-image: url(../../assets/picture/arrow-l.png);
}
.dash-tip {
  display: none;
  background-image: url(../../assets/picture/dash-tip.png);
}
.more-pop {
  background-image: url(../../assets/picture/more-pop.png);
}

.control-bar__icon:hover {
  fill: #c0c0c0;
}

.control-bar__button--large {
  min-width: 4.375em;
  min-height: 50px;
}

.control-bar__label {
  position: relative;
  display: none;
  font-size: 13px;
  color: #36425a;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.icon {
  fill: #36425a;
  cursor: pointer;
}
.icon:hover {
  fill: antiquewhite;
}
.icon_left {
  margin-left: 20px;
}
.pageIndex {
  color: #36425a;
  font-size: 20px;
  height: 50px;
  display: flex;
  align-items: center;
}

.dropdown-icon {
  width: 20px;
  height: 40px;
  display: flex;
  bottom: 10px;
  line-height: 40px;
  overflow: hidden;
  margin-right: 10px;
  margin-left: 20px;
}
.end_button {
  width: 40px;
  height: 40px;
  display: flex;
  bottom: 10px;
  line-height: 40px;
  overflow: hidden;
  margin-right: 20px;
  color: #36425a;
  cursor: pointer;
  font-size: 14px;
}

.info_area {
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}
.hide_area {
  visibility: hidden;
}

.back_focus {
  background-color: #15c39a99;
  border-radius: 8px;
}
.icon-circle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  display: inline-block;
  margin-right: 3px;
  background-color: #36425a;
}
.icon-circle.red-icon {
  background-color: #ff1a0e;
}
.icon-circle.green-icon {
  background-color: rgba(21, 195, 154, 1);
}
.with-outer {
  padding: 3px 13px;
  border-radius: 16px;
  background-color: rgba(54, 66, 90, 0.1);
  margin-right: 0.5rem;
}
.buttondisable {
  opacity: 0.4;
}
</style>
