<template>
  <el-card class="class-room-roster">
    <div class="roster-header flex_j">
      <div class="flex_h flex_c">
        <div class="roster-title">Classroom Roster</div>
        <div class="roster-split">-</div>
        <div>{{session.className}}</div>
        <div class="roster-class-select-tips">
          <el-tooltip class="item" effect="dark" placement="top-start">
            <div slot="content">If you would like to choose class apart from this list,<br/>please add your class to this task first.</div>
            <i class="el-icon-question"></i>
          </el-tooltip>
        </div>
      </div>
      <el-button type="info" icon="el-icon-close" size="mini" circle @click="closeClassRoster"></el-button>
    </div>
    <div class="roster-tabs">
      <el-radio-group v-model="activeTab">
        <el-radio-button label="student">Students ({{ rooms.members.length }})</el-radio-button>
        <el-radio-button label="group">Groups ({{ rooms.groups.length }})</el-radio-button>
        <el-radio-button label="teacher">Teachers ({{ rooms.teachers.length }})</el-radio-button>
      </el-radio-group>
      <div class="roster-group-setting">
        <div v-if="activeTab === 'group'">
          <div class="flex-center">
            <el-button size="small" type="primary" class="classcipe-btn-primary" @click="addGroupFn" :loading="addGroupLoading">Add group</el-button>
            <el-input size="small" type="number" min="1" :class="groupLimitClass" style="width:12rem;margin-left:0.5rem;" v-model="initLimit" placeholder="Enter" >
              <el-tooltip slot="append" class="item" effect="dark" content="Max student no per group" placement="top">
                <el-button @click="setGroupMax" :disabled="!initLimit" :loading="setGroupMaxLoading">Set Limit</el-button>
              </el-tooltip>
            </el-input>
          </div>
        </div>
        <el-button v-else-if="activeTab === 'teacher'" type="primary" size="small" icon="el-icon-share" round class="classcipe-btn-primary" @click="shareDash">Invite co-teacher</el-button>
        <template v-else-if="activeTab === 'student' && session.classId && false">
          <el-button type="primary" size="small" round class="classcipe-btn-primary" @click="handleEvaluateStudent">Evaluate</el-button>
          <el-button type="primary" size="small" round class="classcipe-btn-primary" @click="saveAsClassRoster">Save as class roster</el-button>
        </template>
      </div>
    </div>
    <div class="roster-detail" v-loading="loading">
      <classroom-group v-if="activeTab === 'group'"
        :fns="{ toggleAttendance:handleToggleAttendance, blockStudent:handleBlockStudent }"></classroom-group>
      <classroom-student v-if="activeTab === 'student'"
        :fns="{ toggleAttendance:handleToggleAttendance, blockStudent:handleBlockStudent }"></classroom-student>
      <classroom-teacher v-if="activeTab === 'teacher'"></classroom-teacher>
    </div>

    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :destroy-on-close="true"
      :visible="saveAsRosterModalVisible" :show-close="false"
      center custom-class="custom-dialog" append-to-body
      @close="saveAsRosterModalVisible = false" width="80%">
    <el-card>
      <div class="notice-img">
        <img src="../../assets/icon/notice.png" />
        <div class="notice-title">Notice</div>
      </div>
      <div class="confirm-tips">Confirm to save current student({{newStudentList.length}}) as class roster?</div>
      <div class="class-name-select" v-if="session.className">
        <div class="class-name">Class name</div>
        <div class="input-select">
          {{session.className}}
        </div>
      </div>
      <div class="class-new-student-list">
        <template v-if="newStudentList.length" >
          <div class="new-student" v-for="(student, sId) in newStudentList" :key="sId" @click="student.checked = !student.checked">
            <el-row>
              <el-col :span="4" class="checked-student">
                <el-checkbox v-model="student.checked" fill="#15c39a"></el-checkbox>
              </el-col>
              <el-col :span="10" class="new-student-name">
                <div class="roster-detail-data-item">
                  <student-avatar :student="student"></student-avatar>
                  <div class="student-name">
                    {{ student.name }}
                  </div>
                </div>
              </el-col>
              <el-col :span="10" class="new-student-email">
                <div class="roster-detail-data-item ">
                  {{ student.email }}
                </div>
              </el-col>
            </el-row>
          </div>
        </template>
        <template v-else>
          <div class="no-student">
            No new student
          </div>
        </template>
      </div>
      <div class="action-button">
        <el-button size="small" round class="classcipe-btn action-button-item" @click="saveAsRosterModalVisible = false">Cancel</el-button>
        <el-button size="small" round class="action-button-item classcipe-btn-primary" type="primary" @click="ensureSaveAsClass">Confirm</el-button>
      </div>
    </el-card>
    </el-dialog>
  </el-card>
</template>

<script>
import { mapActions,mapState } from 'vuex'
import copy from "copy-to-clipboard";
import ClassroomGroup from './ClassroomGroup';
import ClassroomStudent from './ClassroomStudent';
import ClassroomTeacher from './ClassroomTeacher';
import StudentAvatar from '../common/studentAvatar';

export default {
  components: { ClassroomGroup, ClassroomStudent, ClassroomTeacher, StudentAvatar },
  name: "ClassroomRoster",
  props: {
    openEvaluate: { type: Function },
  },
  computed: {
    ...mapState(['session', 'classes']),
    ...mapState('teacher', ['teachers', 'rooms', 'classStudents']),
    groupLimitClass() {
      return this.initLimit !== this.initLimitOld && this.initLimit ? '_green' : ''
    },
    newStudentList() {
      const oldList = {}
      for (const o of this.classStudents) oldList[o.email] = 1
      return this.rooms.members.filter(v => !v.email || oldList[v.email])
    }
  },
  data() {
    return {
      sessionId: '',
      studentInfoMap: new Map(),
      teacherInfoMap: new Map(),
      initLimit: 10,
      initLimitOld: 10,
      activeTab: localStorage.getItem('RoomRoster:Tab') || 'student',

      loading: false,
      addGroupLoading: false,
      setGroupMaxLoading: false,
      groupSortAsc: true,
      timeSortAsc: true,
      saveAsRosterModalVisible: false,
      newClassName: '',
      ensureClassId: '',
      refreshOnlineTimer: null,
    }
  },
  created() {
    this.sessionId = this.$route.params.id
    console.log('初始化点名册' + this.sessionId, this.rooms.members.length)
  },
  methods: {
    ...mapActions('teacher', ['addGroup', 'upGroupMax', 'setGroupMember']),
    studentSortList(con = {}) {
      return _.orderBy(_.filter(this.rooms.members, con), !this.timeSortAsc?['nickname']:['last'], !this.timeSortAsc ? ['asc']:['desc'])
    },
    async addGroupFn() {
      this.addGroupLoading = true
      let i = 1
      while(_.find(this.rooms.groups, {name: `Group${i}`})) { i++ }
      const name = `Group${i}`
      await this.addGroup({ name })
      this.addGroupLoading = false
    },
    async setGroupMax() {
      this.setGroupMaxLoading = true
      await this.upGroupMax(this.initLimit)
      this.initLimitOld = this.initLimit
      this.setGroupMaxLoading = false
    },

    listSortChange () {
      this.timeSortAsc = !this.timeSortAsc;
    },
    saveAsClassRoster () {
      console.log('saveAsClassRoster', this.newStudentList)
      this.saveAsRosterModalVisible = true
    },

    async ensureSaveAsClass () {
      let students = []
      for (const o of this.newStudentList) {
        if (!o.checked) continue
        students.push({ avatar: o.avatar, email: o.email, studentName: o.name })
      }
      // 学生student name去重处理，如果有重复的后面累加数字后缀,如:jack、jack1、jack2
      // let studentNameCountMap = new Map()
      // students.forEach(student => {
      //   if (studentNameCountMap.has(student.studentName)) {
      //     student.studentName += studentNameCountMap.get(student.studentName)
      //     studentNameCountMap.set(student.studentName, studentNameCountMap.get(student.studentName) + 1)
      //   } else {
      //     studentNameCountMap.set(student.studentName, 1)
      //   }
      // })
      console.log('ensureSaveAsClass', this.session.classId, students)
      this.saveAsRosterModalVisible = false
      return // todo
      // const rs = await updateClassStudent(this.token, this.session.classId, students).catch((e) => {
      //   console.error('ensureSaveAsClass', e)
      //   this.$message.error('update class roster error ' + e.message)
      //   throw e
      //   return
      // })
      // if (!rs) return
    },

    closeClassRoster () {
      this.$emit('close');
    },

    handleEvaluateStudent () {
      this.openEvaluate()
    },

    async handleToggleAttendance (_id) {
      if (this.loading) return
      this.loading = true
      await this.$store.dispatch('teacher/attendStudent', _id)
      this.loading = false
    },
    async handleBlockStudent(_id, isBlock) {
      if (this.loading) return
      const unblocked = this.rooms.members.filter((member) => !this.rooms.block.includes(member._id))
      if (isBlock && this.classes?.type == 'standard' && unblocked.length >= 10) {
        this.$message({
          message: "The maximum number of participants has been reached. Please block other students on the roster to unblock this student.",
          type: 'info',
        })
      } else {
        this.$confirm(`${isBlock ? 'Unblock' : 'Block'} Student?`, {
          confirmButtonText: 'Done', cancelButtonText: 'Cancel', type: 'warning', confirmButtonClass: 'classcipe-btn-primary',
        }).then(async () => {
          this.loading = true
          await this.$store.dispatch('teacher/blockStudent', _id)
          this.loading = false
        }).catch(() => {})
      }
    },

    shareDash() {
      copy(location.href.split("?")[0])
      this.$notify({ title: 'Link copied successfully', type: 'success' })
    }
  },
}
</script>
<style lang="scss">
@import url(ClassroomRoster.scss);
</style>
