<template>
  <div class="welcome-outer">
    <div class="welcome-header">
      Welcome to Teacher Dashboard
    </div>
    <div class="welcome-content">
      <img src="@/assets/picture/teacher-page/modal-bg-dashboard.png" class="welcome-img" />
      <ul class="welcome-content--text">
        <li class="welcome-info">• You can privately view individual participant responses and manage the whole class;</li>
        <li class="welcome-info">• Make sure the dash window is away from the projector so that the participant privacy is well protected;</li>
        <li class="welcome-info">• Open the dashboard on mobile devices to remotely control your shared projector screen.</li>
      </ul>
    </div>
    <div class="welcome-footer">
      <el-checkbox v-model="isNotShowAgain" class="checkbox__show-again">Don't show this again</el-checkbox>
      <button @click="setIsModalVisible(false), callback()" class="invite-button got-it">I Got it</button>
      <!-- <button @click="setIsModalVisible(false)" class="invite-button got-it">Cancel</button> -->
    </div>
  </div>
</template>

<script>
import {saveStore} from '@/utils/localStore'

const storeKey = 'dashboard-modal-setting'

export default {
  props: {
    setIsModalVisible: {
      type: Function,
    },
    callback: {
      type: Function,
    },
  },
  data() {
    return {
      isNotShowAgain: false,
    }
  },
  watch: {
    isNotShowAgain() {
      saveStore(storeKey, this.isNotShowAgain)
    },
  },
}
</script>

<style lang="scss" scoped>
:global(.checkbox__show-again) {
  color: #fd324f;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 1rem;
  }
}
:global(.el-dialog__wrapper) {
  overflow: hidden;
}
</style>
