<template>
  <div class="header">
    <div class="left-area">
      <div class="dash-header-left" @click="handleBack"></div>
      <div class="ellipsis">
        <b>{{ session.className }}: {{ session.name || 'Unnamed session' }}</b>
      </div>
    </div>
    <!--
    <pre>deadline:{{ session.countdown?.deadline }}</pre>
    <pre>down:{{ session.countdown?.down }}</pre>
    <pre>type:{{ session.countdown?.type }}</pre>
    <pre>studentCtrl:{{ session.countdown?.studentCtrl }}</pre>
    <pre>status:{{ countDownStatus }}</pre>
    -->
    <div class="right-btns">
      <div v-if="session.countdown?.type && countDownStatus != 2" class="countdown flex_c" slot="reference">
        <!--
        <div v-if="countDownStatus > 0" class="flex1">
        </div>
        -->
        <div class="flex1">
          <span>
            <!--
            {{ session.countdown?.type === 1 ? 'Deadline' : 'Countdown' }}
            -->
          </span>
          <span style="padding: 10px"> <i class="el-icon-timer"></i> {{ countdownTime() }} </span>
        </div>
        <!--
          v-if="!(session.countdown?.type === 1 && countDownStatus === 0) && !isStundentPaced"
        -->
        <el-tooltip
          class="item"
          effect="dark"
          v-if="session.sessionType == 'live'"
          :content="session.countdown?.type == 1 ? 'Stop deadline mode' : titles[countDownStatus]"
          placement="bottom">
          <i class="fa" :class="icons[countDownStatus]" @click="countdownBtn" />
        </el-tooltip>
      </div>
      <div class="flex_c" v-if="session.sessionType == 'student' && session.status == 'student' && new Date(session.start) < new Date()">
        <el-tooltip
          class="item"
          effect="dark"
          :content="`${getCountOfExpiredItems()} of roaster ${rooms?.members?.length} students are currently screen-locked.`"
          placement="bottom">
          <div style="padding: 0 10px">{{ getCountOfExpiredItems() }}/{{ rooms.members.length }}</div>
        </el-tooltip>
      </div>
      <div class="flex_c" style="padding-left: 1.5rem" @click="showStudents('teacher')" v-if="coTeacherList.length > 1">
        <el-tooltip v-for="(o, i) in coTeacherList.slice(0, 3)" :key="i" class="item" effect="dark" placement="bottom">
          <div slot="content">{{ o.nickname }}<br />{{ session.uid === o._id ? 'Host' : 'Co-teacher' }}</div>
          <studentAvatar :class="{'-off': !onlineTeachers[o._id]}" :student="o" style="margin-left: -1rem"></studentAvatar>
        </el-tooltip>
        <studentAvatar v-if="coTeacherList.length > 3" :student="{nickname: '+' + (coTeacherList.length - 3)}" style="margin-left: -1rem"></studentAvatar>
        <el-button v-if="session.zoom?.id" size="small" class="theme flex_c" @click="toZoom">
          <div class="flex_c">
            <img src="../../assets/meetings.png" class="flex_c" style="height: 1.2rem" />
            <img src="../../assets/ZoomLogo.png" class="flex_c" style="height: 0.8rem; padding-left: 0.2rem" />
          </div>
        </el-button>
      </div>
      <div class="invite-button" @click="share" v-if="session">
        Code: <span>{{ session.sid }}</span>
      </div>
      <div class="invite-button" v-if="isDashboard" @click="showStudents('student')">Class Roster {{ getStudentOnLineCount }}/{{ rooms.members.length }}</div>
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          <i class="el-icon-more" style="font-size: 2.5rem; color: #92b2d1"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <template v-if="!isDashboard">
            <el-dropdown-item command="openDashBoard">Open Dashboard in New Window</el-dropdown-item>
            <el-dropdown-item command="openDevice" :disabled="session.status === 'close'">Open Dashboard in New Device</el-dropdown-item>
          </template>
          <template v-else>
            <el-dropdown-item command="openProject" :disabled="session.status === 'close'">Present in New Tab</el-dropdown-item>
            <!--
              :disabled="session.status === 'close' || (session.countdown?.type > 0 && countDownStatus > 0) || session.countdown?.studentCtrl">
            -->
            <el-dropdown-item
              command="turnModel"
              :disabled="
                session.status === 'close' ||
                (session.countdown?.type > 0 && countDownStatus > 0) ||
                (session.countdown?.type == 2 && session.status == 'student' && session.sessionType == 'student')
              ">
              {{ isStundentPaced ? 'Stop' : 'Turn on' }} student-paced
            </el-dropdown-item>
            <el-dropdown-item command="" disabled>Ipad/Phone control</el-dropdown-item>
          </template>
          <el-dropdown-item command="reopenClass" divided v-if="session.status === 'close'" :disabled="isMentorOrSubstitute">Reopen session</el-dropdown-item>
          <el-dropdown-item command="endLesson" divided v-else-if="!(isBooking && !isOngoing)">End session</el-dropdown-item>
          <el-dropdown-item command="backtToClass">Sign out</el-dropdown-item>
          <el-dropdown-item command="showTakeaway" divided v-if="session.status === 'close' && !takeaway?._id && (session.students?.length || session.regNum)">Generate takeaway</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog
      v-if="session.countdown?.type > 0 && !loading"
      class="flex_c"
      title="Reminder"
      :visible.sync="showCountDown"
      width="600px"
      :before-close="countDownClose"
      :append-to-body="true"
      :close-on-click-modal="isStoped"
      :show-close="isStoped">
      <div v-if="session.countdown?.type === 2" class="countdownDialog">
        <div v-if="countDownStatus === 0">
          Would like to start counting down and turn to student-pace mode?
          <!--
          Please notice that it will turn to teacher-mode after
          <el-select v-model="countdownVal" placeholder="select" style="width: 3.5rem; margin: 0 0.5rem">
            <el-option v-for="o in countdownOptions" :key="o" :label="o" :value="o"></el-option>
          </el-select>
          minute completes.
          -->
        </div>
        <div v-else>
          {{ confirms[countDownStatus] }}
        </div>
      </div>
      <div v-else-if="session.countdown?.type === 1">
        <div v-if="countDownStatus === 2">Deadline mode has ended</div>
        <div v-else-if="countDownStatus === 1">Confirm stop deadline mode?</div>
      </div>
      <div style="height: 1rem"></div>
      <div class="flex_r">
        <span slot="footer" class="dialog-footer">
          <el-button v-show="isStoped" @click="countDownClose">Cancel</el-button>
          <el-button :type="types[countDownStatus]" @click="countdownFn(1)">{{ btnStr[countDownStatus] }}</el-button>
          <!-- <el-button v-show="countDownStatus === 1" type="danger" @click="countdownFn(2)">{{'Stop and End Session'}}</el-button> -->
        </span>
      </div>
    </el-dialog>
    <el-dialog title="Confirm" :visible.sync="showBackConfirm" :append-to-body="true">
      <div class="pd_t1">Class is still in progress, are you sure you want to leave?</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showBackConfirm = false">Cancel</el-button>
        <el-button @click="endClassroom()" v-if="!(isBooking && !isOngoing)">End the class</el-button>
        <el-button type="primary" @click="backtToClass">Leave without ending the class</el-button>
      </span>
    </el-dialog>
    <el-dialog title="Ending Session" :visible.sync="dialogVisible" :append-to-body="true">
      <div class="dialog_page">
        <div v-if="isStundentPaced">Your session is currently in Student-Paced Mode. Are you sure you want to end your session?</div>
        <div class="flex_r pd_t1" v-if="isStundentPaced">
          <el-button @click="dialogVisible = false">Cancel</el-button>
          <el-button type="danger" @click="endClassroom()">Yes,I'm sure!</el-button>
        </div>
        <div class="flex_r pd_t1" v-else>
          <el-button @click="cancelEndClass()">Cancel</el-button>
          <el-button type="danger" class="confirmBtn" @click="endClassroom()">End Session</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="" :visible.sync="takeawayVisible" :append-to-body="true">
      <div class="dialog_page">
        <div>
          Would you like to generate the takeaways of this session for students? Please notice that once takeaways are generated, students' answers will remain
          consistent with the time of generation and will not be updated further.
        </div>
        <div class="flex_r pd_t1">
          <el-button type="primary" @click="generateTakeaway">Generate now</el-button>
          <el-button @click="hideTakeaway">Not this time</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="newDevice" center :append-to-body="true" :show-close="false" class="flex_c new_device">
      <div class="flex_v flex_l" style="color: #fff">
        <h6 class="pd_1">To Open the Teacher Dashboard on a Second Device:</h6>
        <p>1. Open a web browser on a separate computer or tablet</p>
        <p class="pd_t1">
          2. Go to <a :href="`//${dashUrl}`" target="_blank" class="theme">{{ dashUrl }}</a>
        </p>
        <div class="flex_c pd_1">
          <el-button type="primary" round @click="newDevice = false">Got lt!</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import StudentAvatar from '../common/studentAvatar'
import {showLoading, hideLoading} from '@/utils/loading'
import {mapActions, mapState, mapGetters} from 'vuex'
import PPT from '@/utils/pptConfig'
export default {
  components: {
    StudentAvatar,
  },
  props: {
    openEvaluate: {type: Function},
    share: {type: Function, default: null},
    showStudents: {type: Function},
    reopenClass: {type: Function},
    turnModel: {type: Function},
    confirmModeChange: {type: Function},
  },
  data() {
    return {
      newDevice: false,
      dashUrl: `${PPT.formWebUrl.split('//')[1]}dash`,
      isDashboard: this.$route.name === 'd',
      isOngoing: false,
      dialogVisible: false,
      takeawayVisible: false,
      leaving: false,
      drawer: false,
      loading: false,
      countDownStatus: 0,
      icons: ['fa-play-circle', 'fa-stop-circle', 'fa-stop-circle-o'],
      confirms: ['', 'Confirm stop countdown mode?', 'Countdown mode has ended.'],
      titles: [],
      types: ['primary', 'danger', 'warning'],
      btnStr: ['Start', 'End and evaluate', 'End session and evaluate'],
      countdownOptions: [15, 30, 45, 60, 70],
      countdownVal: '',
      showCountDown: false,
      showBackConfirm: false,
      countdownList: null,
      back: null,
    }
  },
  watch: {
    session: {
      async handler(val) {
        if (val.countdown?.down) this.countdownVal = val.countdown.down
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('student', ['currentPageIndex']),
    ...mapState(['session', 'countDown', 'user', 'takeaway']),
    ...mapState('teacher', ['teachers', 'rooms', 'onlineTeachers']),
    ...mapGetters(['isMentorOrSubstitute']),
    coTeacherList() {
      const list = this.teachers.filter((v) => v._id !== this.session.uid)
      const host = this.teachers.find((v) => v._id === this.session.uid)
      if (host) list.unshift(host)
      return list
    },
    getStudentOnLineCount() {
      return this.$store.getters['teacher/onlineStudentCount']
    },
    isStundentPaced() {
      return this.session.status === 'student'
    },
    isStoped() {
      return this.countDownStatus !== 2
    },
    isBooking() {
      return this.session?.type?.indexOf('booking') != -1
    },
  },
  created() {
    window._ttt = this
    if (PPT.isDev) this.countdownOptions.unshift('0.2')
    this.back = this.$route.query.back
  },
  mounted() {
    this.titles.push('Start countdown mode', 'Stop countdown mode', 'Close countdown mode')
    this.countdownVal = this.countDown.time ?? this.session.countdown?.down
    if (window.countDownIt) clearInterval(window.countDownIt)
    window.countDownIt = setInterval(() => {
      //this.countDownStatus = this.countDown.end === null ? 0 : Date.now() > this.countDown.end ? 2 : 1
      this.countDownStatus = this.session.countdown?.deadline == null ? 0 : Date.now() > new Date(this.session.countdown.deadline).getTime() ? 2 : 1
      if (this.countDown.status !== this.countDownStatus) {
        this.setCountDown({status: this.countDownStatus})
      }
      if (this.countDownStatus === 2) {
        this.showCountDown = true
      }
      if (this.session.status == 'close') {
        this.showCountDown = false
      }
      if (this.session.status == 'student' && this.session.sessionType == 'student' && !this.countdownList) {
        this.getCountdownList()
      }
      this.isOngoing = new Date() > new Date(this.session.start)
      this.$forceUpdate()
    }, 1000)
  },
  methods: {
    ...mapActions(['setCountDown']),
    handleCommand(cmd) {
      if (this[cmd]) this[cmd]()
    },
    toZoom() {
      const {start_url, join_url} = this.session.zoom ?? {}
      const url = this.session.uid === this.user._id ? start_url : join_url
      if (!url) return
      window.open(url, 'zoom', 'height=600, width=800, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no')
    },
    leavePage() {
      if (!history.state.back) return window.close()
      else if (history.state.back) return history.back()
      if (this.session._id) {
        window.location.href = `https://${PPT.isDev ? 'dev.' : ''}classcipe.com/v2/my/content`
      }
    },
    async getCountdownList() {
      this.countdownList = await App.service('rooms').get('countdownList', {query: {sid: this.session.sid}})
    },
    getCountOfExpiredItems(list) {
      let count = 0
      if (this.countdownList) {
        const currentTime = Date.now()

        Object.entries(this.countdownList).forEach(([key, value]) => {
          const itemTime = new Date(value).getTime() + this.session.countdown.down * 60 * 1000
          if (itemTime < currentTime) {
            count++
          }
        })
      }
      return count
    },
    endLesson() {
      this.dialogVisible = true
      console.log(this.dialogVisible)
    },
    cancelEndClass() {
      this.dialogVisible = false
    },
    async endClassroom(noJump) {
      //结束课程
      showLoading()
      this.dialogVisible = false
      this.setCountDown({status: 0})
      await this.$store.dispatch('upSession', {countdown: {type: 0, down: null, deadline: null, studentCtrl: false}, status: 'close'})
      hideLoading()
      if (!this.takeaway?._id && (this.session?.students?.length || this.session?.regNum)) {
        this.takeawayVisible = true
        this.leaving = true
      } else if (!noJump) {
        this.backtToClass()
      }
      //this.leavePage()
    },
    showTakeaway() {
      this.takeawayVisible = true
    },
    hideTakeaway() {
      this.takeawayVisible = false
      if (this.leaving) {
        this.backtToClass()
      }
    },
    async generateTakeaway(noJump) {
      try {
        await App.service('session-snapshot').get('snapshot', {
          query: {_id: this.session._id},
        })
      } catch (e) {}
      setTimeout(() => {
        window.location.href = `https://${PPT.isDev ? 'dev.' : ''}classcipe.com/v2/account/takeaway/${this.session._id}`
      }, 100)
    },
    openDevice() {
      this.newDevice = true
    },
    openProject() {
      const url = `${location.origin}/t/${this.session.sid}`
      var strWindowFeatures = 'width=1200,height=750,menubar=yes,location=yes,resizable=yes,scrollbars=true,status=true,top=100,left=200'
      window.open(url, 'project', strWindowFeatures)
    },
    openDashBoard() {
      const url = `${location.origin}/d/${this.session.sid}`
      var strWindowFeatures = 'width=960,height=640,menubar=yes,resizable=yes,scrollbars=true,status=true,top=100,left=200'
      window.open(url, 'dashboard', strWindowFeatures)
    },
    async countdownBtn() {
      if (this.session.status === 'close') {
        if (!this.isMentorOrSubstitute) {
          this.$message.info('Please reopen session first')
        }
        return
      }
      if (!this.showCountDown) {
        this.showCountDown = true
      }
    },
    countdownTime() {
      if (this.countDownStatus != 1) return `${this.session.countdown.down} mins`
      //const ex = parseInt((this.countDown.end - new Date().getTime()) / 1000)
      const ex = parseInt((new Date(this.session.countdown.deadline).getTime() - new Date().getTime()) / 1000)
      const srr = []
      const h = parseInt((ex % 86400) / 3600)
      const m = parseInt((ex % 3600) / 60)
      const s = parseInt(ex % 60)
      srr.push(h < 10 ? '0' + h : h, m < 10 ? '0' + m : m, s < 10 ? '0' + s : s)
      //if (ex > 24 * 3600) {
      // return new Date(this.session.countdown.deadline).toLocaleString()
      // }
      return (ex > 86400 ? parseInt(ex / 86400) + ' Day ' : '') + srr.join(':')
      /*
      let sec = ex % 60
      let min = parseInt(ex / 60)
      if (min <= 0 && sec < 0) return '00:00'
      if (min < 10) min = '0' + min
      if (sec < 10) sec = '0' + sec
      return `${min}:${sec}`
      */
    },
    async countdownFn(f) {
      if (this.loading) return
      this.loading = true
      if (this.countDownStatus > 0) {
        this.countDownClose()
        await this.endClassroom(1)
        this.openEvaluate()
        await this.setCountDown({time: this.countdownVal, status: 0})
        this.loading = false
        return console.log('close countDown mode', this.countDown.end)
      }
      // open to student-paced
      if (!this.isStundentPaced) {
        this.$store.dispatch('upSession', {status: 'student'})
      }
      this.setCountDown({time: this.countdownVal, status: 1})

      const data = {...this.session.countdown, ...{deadline: new Date(new Date().getTime() + this.session.countdown?.down * 60 * 1000)}}
      //console.log(this.session.countdown, data, '<=============countdownBtn')
      await this.$store.dispatch('upSession', {countdown: data})

      this.countDownClose()
      console.log('start countDown mode', this.countdownVal, this.countDown.end)
      this.loading = false
    },
    countDownClose() {
      this.showCountDown = false
    },
    handleBack() {
      if (this.session.status == 'close') {
        this.backtToClass()
      } else {
        this.showBackConfirm = true
      }
    },
    backtToClass() {
      if (this.$route.query.back) {
        setTimeout(() => {
          window.location.href = `https://${PPT.isDev ? 'dev.' : ''}classcipe.com/v2${this.$route.query.back}`
        }, 1)
      } else {
        try {
          window.close()
        } catch (e) {
          console.log(e, '<=================')
        }
        setTimeout(() => {
          window.location.href = `https://${PPT.isDev ? 'dev.' : ''}classcipe.com/v2/home/index`
        }, 100)
      }
      //if (history.state.back) return window.history.back()
    },
  },
}
</script>
<style>
.new_device {
  background: #333333dd;
}
.new_device .el-dialog {
  background: transparent;
}
.countdown {
  background-color: aliceblue;
  border-radius: 2.2rem;
  overflow: hidden;
  padding: 0.1rem 0.2rem;
}
.countdown > div:last-child {
  padding: 0 0.5rem;
  font-size: 1.2rem;
}
.countdown i {
  font-size: 2.2rem;
  color: #15c39a;
  position: relative;
}
.countdown i.fa-play-circle {
  color: #15c39a;
  cursor: pointer;
}
.countdown i.fa-stop-circle-o {
  color: darkorange;
  cursor: pointer;
}
.countdownDialog .el-input__inner {
  padding: 0 0.5rem;
  text-align: center;
}
.countdown i.el-icon-timer {
  font-size: 1rem;
}
.countdown i.fa-stop-circle {
  color: crimson;
  cursor: pointer;
  animation: breath 0.6s linear 0.5s infinite alternate;
}
@keyframes breath {
  0% {
    text-shadow: 0 0 0.2rem red;
  }
  100% {
    text-shadow: 0 0 0rem red;
  }
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}
</style>
