<template>
  <div class="welcome-outer">
    <div class="welcome-header">
      The Student-pace mode is on now
      <div @click="cancelModeChange()" class="common-close-btn"></div>
    </div>
    <div class="welcome-content">
      <img src="@/assets/picture/teacher-page/modal-bg-student-welcome.png" class="welcome-img" />
      <ul class="welcome-content--text">
        <li class="welcome-info">• Your participants can freely navigate through all slides.</li>
        <li class="welcome-info">• Their screen will neither display your projector content nor be affected by operations from your portal.</li>
        <li class="welcome-info">• You can turn off the student-pace anytime from the dropdown list on the top right corner.</li>
      </ul>
    </div>
    <div class="welcome-footer">
      <!-- <button @click="setIsModalVisible(false), callback()" class="invite-button got-it">I got it</button> -->
      <!-- <div class="confirm_btn" @click="confirmModeChange()"> -->
      <button class="invite-button cancel" @click="cancelModeChange()">
        <strong>Cancel</strong>
      </button>

      <button class="invite-button got-it" @click="confirmModeChange()">
        <strong>Confirm</strong>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    confirmModeChange: {
      type: Function,
    },
    cancelModeChange: {
      type: Function,
    },
  },
};
</script>
<style lang="scss" scoped>
.welcome-info {
  word-break: normal;
}
:global(.checkbox__show-again) {
  color: #fd324f;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 1rem;
  }
}
:global(.el-dialog__wrapper) {
  overflow: hidden;
}
</style>
