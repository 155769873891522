<template>
  <div>
    <!-- Modals -->
    <el-dialog v-if="isDashboard" :visible.sync="isModalDashboardVisible" :show-close="false" :close-on-click-modal="false" width="80%">
      <ModalDashboard :setIsModalVisible="setIsModalDashboardVisible" :callback="callModalsQueueIfRemainder" />
    </el-dialog>

    <el-dialog
      v-if="!isModalStudentWelcomeNotShowAgain"
      :visible.sync="isModalStudentWelcomeVisible"
      :show-close="false"
      :close-on-click-modal="false"
      width="80%">
      <ModalStudentWelcome :setIsModalVisible="setIsModalStudentWelcomeVisible" :callback="callModalsQueueIfRemainder" />
    </el-dialog>

    <el-dialog :visible.sync="isModalTeacherWelcomeVisible" :show-close="false" :close-on-click-modal="false" width="80%">
      <ModalTeacherWelcome :setIsModalVisible="setIsModalTeacherWelcomeVisible" :callback="callModalsQueueIfRemainder" />
    </el-dialog>

    <el-dialog :visible.sync="isModalStudentExplainVisible" :show-close="false" :close-on-click-modal="false" width="80%">
      <ModalStudentExplain :setIsModalVisible="setIsModalStudentExplainVisible" :callback="callModalsQueueIfRemainder" />
    </el-dialog>
  </div>
</template>
<script>
import ModalStudentWelcome from '@/components/teacher/modals/StudentWelcome.vue'
import ModalStudentExplain from '@/components/teacher/modals/StudentExplain.vue'
import ModalTeacherWelcome from '@/components/teacher/modals/TeacherWelcome.vue'
import {mapActions, mapState} from 'vuex'
import {getStore} from '@/utils/localStore'
import ModalDashboard from '@/components/teacher/modals/Dashboard.vue'

export default {
  components: {
    ModalStudentWelcome,
    ModalStudentExplain,
    ModalTeacherWelcome,
    ModalDashboard,
  },
  props: ['isDashboard', 'guideOkCall'],
  computed: {
    ...mapState(['session']),
    isModalDashboardNotShowAgain() {
      return getStore('dashboard-modal-setting')
    },
    isModalTeacherWelcomeNotShowAgain() {
      return getStore('teacher-welcome-modal-setting')
    },
    isModalStudentWelcomeNotShowAgain() {
      return getStore('student-welcome-modal-setting')
    },
  },
  data() {
    return {
      sessionStatusKeys: {
        student: 'student',
      },
      modalsQueue: [],
      // modals
      isModalDashboardVisible: false,
      isModalStudentWelcomeVisible: false,
      isModalStudentExplainVisible: false,
      isModalTeacherWelcomeVisible: false,
    }
  },
  mounted() {
    const openFromInside = !!document.referrer && new URL(document.referrer).hostname === location.hostname && /\/(t|d)\//.test(document.referrer)

    // modals setting
    if (!openFromInside) {
      if (this.isDashboard) {
        if (!this.isModalDashboardNotShowAgain) this.modalsQueue.push(() => this.setIsModalDashboardVisible(true))
      }
      // student paced or teacher paced
      if (this.session.status === this.sessionStatusKeys.student) {
        this.modalsQueue.push(() => this.setIsModalStudentExplainVisible(true))
      } else {
        this.modalsQueue.push(() => this.setIsModalTeacherWelcomeVisible(true))
      }
    }

    console.warn('all modals', openFromInside, this.modalsQueue.length, this.session.status, this.isModalDashboardNotShowAgain)
    this.callModalsQueueIfRemainder()
  },
  methods: {
    setIsModalDashboardVisible(bool) {
      this.isModalDashboardVisible = bool
    },
    setIsModalStudentWelcomeVisible(bool) {
      this.isModalStudentWelcomeVisible = bool
    },
    setIsModalStudentExplainVisible(bool) {
      this.isModalStudentExplainVisible = bool
    },
    setIsModalTeacherWelcomeVisible(bool) {
      this.isModalTeacherWelcomeVisible = bool
    },
    callModalsQueueIfRemainder() {
      if (this.modalsQueue.length > 0) {
        this.modalsQueue[0]()
        this.modalsQueue.shift()
      }
      if (this.modalsQueue.length === 0) this.guideOkCall()
    },
  },
}
</script>
