<template>
  <div class="res-and-list">
    <ul class="res-list" v-if="tab === 1">
      <li class="student-list-item" v-for="item in studentList.slice(0, 200)" :key="item._id">
        <img src="../../assets/picture/student-answered.png" class="ans-status" v-if="answeredStudents.indexOf(item._id) > -1"/>
        <img src="../../assets/picture/student-no-ans.png" class="ans-status" v-else/>
        <div class="user-icon student-icon">{{item.name ? item.name.substr(0, 2) : ''}}</div>
        <div class="user-name">{{item.name}}</div>
      </li>
    </ul>
    <ul class="res-list" v-if="tab === 2">
      <!-- <div  class="showResButoon">{{showResponse ? 'Hide' : 'Show'}} Response</div> -->
      <template v-if="answerList.length > 0">
        <li class="res-list-item" v-for="item in answerList" :key="item._id">
          <div class="res-list-item-content">
            <div class="ans-detail" v-if="getQuestion">
              <dash-right-media-item v-if="getQuestion.type === 'media'" :item="item"/>
              <dash-right-comment-item v-if="getQuestion.type === 'comment'" :item="item"/>
              <div v-if="getQuestion.type === 'draw'" class="remark-item-content-right" style="height: 150px">
                <Base64image :url="Fn.hashToUrl(getPage.pic || getPage.url)">
                  <div class="del-answer flex_c" v-if="item.locked">Deleted response</div>
                  <Base64image v-else :url="item.content"/>
                </Base64image>
              </div>
              <div class="remark-item-content-right" v-if="['choice', 'text'].includes(getQuestion.type)">
                <div :class="item.content? '' : 'deleted-response'">{{item.content || "Deleted Response"}}</div>
              </div>
            </div>
            <div class="user-info">
              <studentInfo :data="{ uid: item.uid, nickname: item.nickname, last: item.updatedAt }" />
            </div>
          </div>
        </li>
      </template>
      <loading-view v-else/>
    </ul>
  </div>
</template>
<script>
import { getAnswerTimeStr } from '@/utils/help'
import { mapState, mapGetters } from 'vuex'
import studentInfo from '../common/studentInfo.vue'
import dashRightMediaItem from './dash-answer/dash-right-media-item.vue'
import DashRightCommentItem from './dash-answer/dash-right-comment-item.vue'
import Base64image from '@/components/base64image.vue';
import LoadingView from './loadingView.vue'
export default {
  components: { studentInfo, dashRightMediaItem, DashRightCommentItem, Base64image, LoadingView },
  computed: {
    ...mapState({
      studentList: state => state.teacher.studentList,
      allRemarks: state => state.remark.allRemarks,
    }),
    ...mapGetters(['getPageId', 'getPage', 'getQuestion']),
    ...mapGetters('student', ['currentPageAnswerList']),
    // 已答题学生
    answeredStudents() {
      let anList = []
      for(let i = 0; i < this.studentList.length; i++) {
        const currentUser = this.studentList[i]
        const index = this.answerList.findIndex(item => item._id === currentUser._id)
        if(index > -1) {
          anList.push(currentUser)
        }
      }
      // console.log(noList)
      return anList
    },
    currentComments() {
      let list = []
      if(this.getPageId && this.allRemarks.length > 0) {
        list = this.allRemarks.filter(
          item => item.page_id === this.getPageId
        );
      }
      // list.reverse()
      console.log(list)
      return list;
    },
    answerList() {
      Acan.arrSort(this.currentPageAnswerList, 'updatedAt', 1)
      return this.currentPageAnswerList
    }
  },
  props: {
    showResponse: {
      type: Boolean,
      default: false,
    },
    showres: {
      type: Function
    },
  },
  data() {
    return {
      tab: 2
    }
  },
  methods: {
    changeTab(tab) {
      this.tab = tab
    },
    getTimeStr(time) {
      if(!time) return ''
      return getAnswerTimeStr(time * 1000);
    },
  }
}
</script>
