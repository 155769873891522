<template>
  <div class="welcome-outer">
    <div class="welcome-header">
      The Student-pace mode is on now
    </div>
    <div class="welcome-content">
      <img src="@/assets/picture/teacher-page/modal-bg-student-welcome.png" class="welcome-img" />
      <ul class="welcome-content--text">
        <li class="welcome-info">• Your participants can freely navigate through all slides.</li>
        <li class="welcome-info">• Their screen will neither display your projector content nor be affected by operations from your portal.</li>
        <li class="welcome-info">• You can turn off the student-pace anytime from the dropdown list on the top right corner.</li>
      </ul>
    </div>
    <div class="welcome-footer">
      <el-checkbox v-model="isNotShowAgain" class="checkbox__show-again">Don't show this again</el-checkbox>
      <button @click="setIsModalVisible(false), callback()" class="invite-button got-it">I got it</button>
    </div>
  </div>
</template>

<script>
import {saveStore} from '@/utils/localStore'

const storeKey = 'student-welcome-modal-setting'

export default {
  props: {
    setIsModalVisible: {
      type: Function,
    },
    callback: {
      type: Function,
    },
    storeKey: {
      type: String,
    },
  },
  data() {
    return {
      isNotShowAgain: false,
    }
  },
  watch: {
    isNotShowAgain() {
      saveStore(storeKey, this.isNotShowAgain)
    },
  },
}
</script>

<style lang="scss" scoped>
.welcome-info {
  // position: absolute;
  word-break: normal;
}
:global(.checkbox__show-again) {
  color: #fd324f;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 1rem;
  }
}
:global(.el-dialog__wrapper) {
  overflow: hidden;
}
</style>
