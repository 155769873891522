<template>
  <el-form ref="form" :model="formData" label-width="8rem" v-loading="loading">
    <el-form-item label="Starts when" prop="start">
      <div class="time-type dead-line">
        <el-input id="flatStart" v-model="formData.start" placeholder="Select Date.."> </el-input>
      </div>
    </el-form-item>
    <el-form-item label="Ends when" prop="end" v-if="isLiveSession">
      <div class="time-type dead-line">
        <el-input id="flatEnd" v-model="formData.end" placeholder="Select Date.."> </el-input>
      </div>
    </el-form-item>
    <el-form-item label="Ends when" prop="deadline" v-else>
      <el-radio-group v-model="time_type">
        <el-radio :label="1">Deadline</el-radio>
        <el-radio :label="2">Countdown</el-radio>
      </el-radio-group>
      <div class="allocate-time" v-show="time_type > 0">
        <div class="time-type dead-line" v-show="time_type === 1">
          <el-input id="flatDeadline" v-model="formData.deadline" placeholder="Select Date.."> </el-input>
        </div>
        <div class="time-type count-down" v-show="time_type === 2">
          <el-select v-model="formData.down" placeholder="--Select--" class="my-login-input">
            <el-option v-for="item in timeCounts" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
      </div>
    </el-form-item>
    <div class="flex_c">
      <el-button type="" @click="close()">Cancel</el-button>
      <el-button type="primary" :loading="loading" :disabled="!readyToSubmit" @click="submit($event)">Confirm</el-button>
    </div>
  </el-form>
</template>
<script>
import {mapState} from 'vuex'
export default {
  props: {
    close: {type: Function},
  },
  computed: {
    ...mapState(['session']),
    isLiveSession() {
      return this.session.sessionType === 'live'
    },
    readyToSubmit() {
      if (this.isLiveSession) {
        return !!this.formData.start && !!this.formData.end
      } else {
        return !!this.formData.start
      }
    },
  },
  data() {
    return {
      loading: false,
      dateConf: {
        enableTime: true,
        time_24hr: true,
        minuteIncrement: 1,
        dateFormat: 'Y-m-d H:i:S',
      },
      time_type: 1,
      timeCounts: [
        {value: 15, label: '15 min'},
        {value: 30, label: '30 min'},
        {value: 45, label: '45 min'},
        {value: 60, label: '60 min'},
        {value: 70, label: '70 min'},
      ],
      formData: {
        deadline: '',
        start: '',
        end: '',
        down: '',
      },
      flatpickr: {},
    }
  },
  async mounted() {
    this.initFlatpickr('flatStart')
    if (this.isLiveSession) {
      this.initFlatpickr('flatEnd')
    } else {
      this.initFlatpickr('flatDeadline')
    }
  },
  methods: {
    initFlatpickr(id) {
      if (this.flatpickr[id]) {
        this.flatpickr[id].destroy()
        this.flatpickr[id] = null
      }
      const allocateOpt = {
        ...this.dateConf,
        minDate: new Date(),
        onChange: (selectedDates, dateStr, instance) => {
          if (id == 'flatStart') {
            this.formData.end = null
            this.formData.deadline = null
          }
        },
        onClose: (selectedDates, dateStr, instance) => {
          setTimeout(() => {
            if (id == 'flatStart') {
              if (this.isLiveSession) {
                this.initFlatpickr('flatEnd')
              } else {
                this.initFlatpickr('flatDeadline')
              }
            }
          }, 500)
        },
      }

      if (id !== 'flatStart' && this.formData.start) {
        allocateOpt.minDate = new Date(this.formData.start)
      }
      this.flatpickr[id] = flatpickr(`#${id}`, allocateOpt)
    },
    async submit(e) {
      this.loading = true
      const {start, end, deadline, down} = this.formData
      const query = {status: 'live', start: new Date(start).toISOString()}
      if (this.isLiveSession) {
        query.end = new Date(end).toISOString()
      } else {
        query.countdown = {type: 0, deadline: null, down: 0}
        query.status = 'student'
        query.studentCtrl = false
        if (this.time_type === 1 && deadline) {
          query.countdown.type = 1
          query.countdown.deadline = new Date(deadline).toISOString()
        } else if (this.time_type === 2 && down) {
          query.countdown.type = 2
          query.countdown.down = down
        }
      }

      await this.$store.dispatch('upSession', query)
      this.close()
    },
  },
}
</script>
<style>
.el-input__inner {
  font-size: 1rem;
}
</style>
<style scoped>
.el-radio {
  margin-right: 1rem;
}
.el-form-item {
  margin-bottom: 1rem;
}

.my-login-input {
  width: 100%;
}
</style>
