<template>
  <div class="welcome-outer">
    <div class="welcome-header">
      The Student-pace explanation
    </div>
    <div class="welcome-content">
      <img src="@/assets/picture/teacher-page/modal-bg-student-explain.png" class="welcome-img" />
      <ul class="welcome-content--text">
        <li class="welcome-info">• Student-pace mode works best when participants take charge of their own learning, such as taking quizzes, assignments and etc.</li>
        <li class="welcome-info">• You can track their progress and review their responses via Teacher Dashboard.</li>
      </ul>
    </div>
    <div class="welcome-footer">
      <button @click="setIsModalVisible(false), callback()" class="invite-button got-it">I got it</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    setIsModalVisible: {
      type: Function,
    },
    callback: {
      type: Function,
    },
    turnOnStudentPaced: {
      type: Function,
    }
  },
}
</script>

<style lang="scss" scoped>
check
.welcome-info {
  // position: absolute;
  word-break: normal;
}
:global(.checkbox__show-again) {
  color: #fd324f;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 1rem;
  }
}
:global(.el-dialog__wrapper) {
  overflow: hidden;
}
</style>
