<template>
  <div :class="`${['Deadline', 'Countdown'].includes(data.state) ? 'negative' : 'info'}`">
    <div v-if="data.valid || ['Deadline', 'Countdown'].includes(data.state)">
      <div v-if="loading">loading...</div>
      <div v-else-if="data.state" class="timewrapper">
        <span v-if="data.end" class="info"> <i class="el-icon-circle-check"></i>{{ data.end }} </span>
        <span class="">
          <!--
          {{ data.state }} :
          -->
          <i :class="icons[data.state]"></i>{{ data.format }}
        </span>
        <span v-if="data.status === 'Scheduled' && data.duration"> <i class="el-icon-time"></i>{{ data.duration }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  computed: {},
  props: {
    session: {type: Object},
  },
  watch: {
    session: {
      deep: true,
      handler(newValue, oldValue) {
        this.clearAllInterval()
        this.init()
      },
    },
  },
  data() {
    return {
      data: {},
      rangeHour: 24,
      loading: false,
      icons: {Start: 'el-icon-video-play', End: 'el-icon-circle-check', Deadline: 'el-icon-timer', Countdown: 'el-icon-timer'},
      countHandle: null,
    }
  },
  beforeDestroy() {
    this.clearAllInterval()
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      this.data = this.getSessionData()

      if (this.data.reducing) {
        this.countHandle = setInterval(this.countDown, 1000)
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    format: function (date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    getSessionData() {
      const now = new Date()
      const obj = {inRange: false, reducing: false, valid: true, state: null, duration: 0, start: null, end: null}

      let targetTime = null
      if (new Date(this.session.start) > now) {
        targetTime = this.session.start
        obj.state = 'Start'
        obj.status = 'Scheduled'
        obj.reducing = true
        if (this.session.countdown.type == 1 && this.session.countdown.deadline) {
          obj.duration = new Date(this.session.countdown.deadline) - new Date(this.session.start)
        } else if (this.session.countdown.type == 2 && this.session.countdown.deadline) {
          obj.duration = this.session.countdown.down * 60 * 1000
        } else if (this.session.end) {
          obj.duration = new Date(this.session.end) - new Date(this.session.start)
        }
      } else if (new Date(this.session.start) < now) {
        obj.status = 'Ongoing'
        if (this.session.countdown?.deadline) {
          targetTime = this.session.countdown?.deadline
          obj.end = this.format(targetTime)
          if (this.session.countdown.type == 1) {
            obj.state = 'Deadline'
          } else if (this.session.countdown.type == 2) {
            obj.state = 'Countdown'
          }
          if (!this.session.countdown.studentCtrl && new Date(targetTime) > now) {
            obj.reducing = true
          }
        } else if (!this.session.countdown?.deadline && this.session.countdown.type == 2 && this.session.countdown.down) {
          //targetTime = this.session.countdown.down
          targetTime = this.session.end
          obj.state = 'End'
          if (this.session.sessionType == 'student') {
            targetTime = this.session.countdown.down
            obj.valid = false
            obj.state = 'Countdown'
          }
          //obj.valid = true
        } else if (this.session.end || (this.session.status == 'close' && this.session.ended)) {
          obj.state = 'End'
          targetTime = this.session.end
          if (this.session.status == 'close') {
            obj.status = 'Ended'
            if (this.session.ended) {
              targetTime = this.session.ended
            }
          }
        }
      }

      if (obj.valid) {
        obj.format = this.format(targetTime) //new Date(targetTime).toLocaleString()
        if (['Deadline', 'Countdown'].includes(obj.state) && new Date(targetTime) < now) {
          obj.format = 'Time is up'
        }
      } else {
        obj.format = `${targetTime} mins`
      }

      if (obj.status == 'Scheduled' && obj.duration) {
        const minutes = (obj.duration / (1000 * 60)).toFixed()
        const hour = parseInt(minutes / 60)
        const minute = parseInt(minutes % 60)
        obj.duration = `${hour ? hour + ' hour' + (hour > 1 ? 's' : '') : ''} ${minute ? minute + ' min' + (minute > 1 ? 's' : '') : ''}`
      }
      obj.target = targetTime

      return obj
    },
    async countDown() {
      const ex = new Date(this.data.target) - new Date()
      if (ex > 0) {
        this.data.valid = true
      } else {
        this.data.valid = false
      }
      if (this.data.valid) {
        const withinRange = ex <= this.rangeHour * 3600 * 1000
        const outOfRange = ex > this.rangeHour * 3600 * 1000
        if (withinRange && this.data.inRange == false) {
          this.data.inRange = true
        } else if (outOfRange && this.data.inRange == true) {
          this.data.inRange = false
        }
        if (['Deadline', 'Countdown'].includes(this.data.state) && this.data.inRange) {
          this.data.format = this.countDownFormat(ex / 1000)
        }
      } else {
        this.clearAllInterval()
        if (['Deadline', 'Countdown'].includes(this.data.state)) {
          this.data.format = 'Time is up'
        } else {
          await this.init()
        }
      }
    },
    clearAllInterval() {
      if (this.countHandle) clearInterval(this.countHandle)
    },
    countDownFormat(ex) {
      const srr = []
      const h = parseInt((ex % 86400) / 3600)
      const m = parseInt((ex % 3600) / 60)
      const s = parseInt(ex % 60)
      srr.push(h < 10 ? '0' + h : h, m < 10 ? '0' + m : m, s < 10 ? '0' + s : s)
      return (ex > 86400 ? parseInt(ex / 86400) + 'Day' + (parseInt(ex / 86400) > 1 ? 's ' : ' ') : '') + srr.join(':')
    },
  },
}
</script>
<style scoped>
.negative {
  color: #f56c6c;
}
.info {
  color: #909399;
}

.timewrapper span {
  padding-right: 15px;
}
.timewrapper i {
  padding-right: 4px;
}
</style>
