<template>
<div class="student-list">
  <el-table id="tableData" empty-text=" " :data="studentSortList" style="width: 100%" height="600">
    <el-table-column label="Name">
      <template slot="header">
        <div class="flex_h">
          Name
          <div class="roster-header-action-item" @click="listSortChange">
            <div class="roster-action-icon" :class="{'sort-asc': timeSortAsc}"><i class="el-icon-sort"></i></div>
            <div class="roster-action-text">{{timeSortAsc?"Sort by Time":"Sort by first letter A-Z"}}</div>
          </div>
        </div>
      </template>
      <template slot-scope="scope">
        <div class="flex_l">
          <student-avatar :class="{'-off': !onlineList[scope.row._id]}" :student="scope.row"></student-avatar>
          <div class="flex_c" :class="{blockline:rooms.block.includes(scope.row._id)}">{{ scope.row.nickname || scope.row.name?.join(' ')}}</div>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Attendance">
      <template slot-scope="scope">
        <div v-if="scope.row.last" class="roster-detail-data-item" :class="{'absent-student': !rooms.attend.includes(scope.row._id)}" @click="fns.toggleAttendance(scope.row._id)">
          <div class="student-attendance">{{rooms.attend.includes(scope.row._id) ? 'Attended' : 'Absent'}}</div>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Email" prop="email">
      <template slot-scope="scope">
        {{ scope.row.email?.includes('@') ? scope.row.email:'/' }}
      </template>
    </el-table-column>
    <el-table-column label="Block">
      <template slot-scope="scope">
        <div style="width:80px;" class="roster-data-action roster-block" @click="fns.blockStudent(scope.row._id, rooms.block.includes(scope.row._id))">
          {{!rooms.block.includes(scope.row._id) ? 'Block' : 'Unblock'}}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="">
      <template slot-scope="scope">
        <el-dropdown @command="handleCommand($event, scope.row)" v-if="scope.row.email?.split('@')?.[1] == 'classcipe.com' && scope.row.uid && scope.row.id && scope.row.password">
          <span class="el-dropdown-link">
            <i class="el-icon-more el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="password">Show ID & Password</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </el-table-column>
  </el-table>
  <div class="flex_r h_3">
    <el-pagination class="flex_c" :current-page.sync="page"
      :hide-on-single-page="false"
      :page-size="pageSize"
      :total="total"
      layout="prev, pager, next">
    </el-pagination>
  </div>
  <el-dialog
    :visible.sync="dialogVisible"
    append-to-body
    width="30%">
    <div>
      <span>Student ID: {{student?.id}}</span>
    </div>
    <div>
      <span>Initial password: {{student?.password}}</span>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">Cancel</el-button>
      <el-button type="primary" @click="onCopyClick">Copy</el-button>
    </span>
  </el-dialog>
</div>
</template>
<script>
import { mapState } from 'vuex'
import StudentAvatar from '../common/studentAvatar';
export default {
  components: { StudentAvatar },
  data() {
    return {
      timeSortAsc: true,
      page: 0,
      student: null,
      pageSize: 15,
      dialogVisible: false,
      total: 0
    }
  },
  props: {
    fns: { type: Object, required: true }
  },
  computed: {
    ...mapState('teacher', ['rooms', 'onlineList']),
    studentSortList() {
      const attended = this.rooms.members.filter((item) => item.last)
      const unattended = this.rooms.members.filter((item) => !item.last)
      const attendedOrdered = _.orderBy(attended, !this.timeSortAsc?['name']:['last'], !this.timeSortAsc ? ['asc']:['desc'])
      const list = [...attendedOrdered, ...unattended]
      this.total = list.length
      const skip = (this.page-1)*this.pageSize
      return list.slice(skip, skip + this.pageSize)
    }
  },
  mounted() {
  },
  methods: {
    listSortChange () {
      this.timeSortAsc = !this.timeSortAsc
      this.$forceUpdate()
    },
    handleCommand(command, student) {
      //todo https://dev.classcipe.com/doc/#/fio/session?id=roaster
      if (command == 'password') {
        this.student = student
        this.dialogVisible = true
      }
    },
    onCopyClick() {
      this.dialogVisible = false
      navigator.clipboard.writeText(`Student ID: ${this.student.id} \nInitial password: ${this.student.password}`)
      this.$message({
        message: 'ID & Password has been copied to clipboard.',
        type: 'success'
      });
    },
  },
}
</script>
<style lang="scss">
.student-list{overflow: hidden!important;}
.student-list td{padding: 0.2rem 0;}
</style>
