<template>
  <div class="dashboard">
    <div class="dashboardpage" :style="`height:${height - 110}px`">
      <dashTopPptList v-show="showPPTList" :changePage="changePage" />
      <div :class="`dash-second ${showFullAnswer && 'dash-border'} ${((showResponse && getQuestion && !isStudentPaced) || isLockPage) && 'red-dash-border'}`">
        <div :class="`dash-second-left ${!showFullAnswer && 'dash-border'}`">
          <teacherIndexItem
            v-if="getQuestion && showFullAnswer"
            :flag_1="true"
            :currentAnswerCount="currentAnswerCount"
            :textList="responseContentList"
            :isDash="true" />
          <pptDetail v-if="!(showFullAnswer && getQuestion)" :showLogo="true" />
          <DashboardMeterial v-if="!showFullAnswer || !getQuestion" />
        </div>
        <template v-if="getQuestion && getQuestion.type !== 'website'">
          <div v-if="!showFullAnswer" class="dash-second-right">
            <DashResAndStudents :showResponse="showResponse" :responseList="responseContentList" />
          </div>
          <div v-else :class="`dash-second-right dash-students dash-pad-students ${padTab === 2 && 'dash-pad-hidden'}`">
            <DashGroupStudents />
          </div>
        </template>
        <DashSwitchHeader :showResponse="showResponse" :padTab="padTab" :changePadTab="changePadTab" v-if="getQuestion && getQuestion.type !== 'website'" />
      </div>
      <TipsList v-if="overviewModalVisiable" />
      <div :class="`shouqi ${!showPPTList && 'zhankai'} ppt-shouqi`" @click="togglePPTList"></div>
    </div>
  </div>
</template>

<script>
import TipsList from '../common/tipsList.vue'
import DashboardMeterial from './dashboardMeterial.vue'
import DashResAndStudents from './dashResAndStudents.vue'
import teacherIndexItem from './Index.vue'
import dashTopPptList from './dash/dashTopPptList.vue'
import DashSwitchHeader from './dash/dashSwitchHeader.vue'
import DashGroupStudents from './dashGroupStudents.vue'
import {mapState, mapGetters} from 'vuex'
import pptDetail from '../pptDetail.vue'
export default {
  components: {DashSwitchHeader, teacherIndexItem, DashboardMeterial, TipsList, DashResAndStudents, dashTopPptList, DashGroupStudents, pptDetail},
  props: {
    currentAnswerCount: {
      type: Number,
      default: 0,
    },
    responseContentList: {
      type: Array,
      function() {
        return []
      },
    },
    responsePercentage: {
      type: Array,
      function() {
        return []
      },
    },
    overviewModalVisiable: {
      type: Boolean,
      default: false,
    },
    changePage: {
      type: Function,
    },
  },
  data() {
    return {
      height: window.winHeight,
      showPPTList: true,
      padTab: 2,
    }
  },
  computed: {
    ...mapState('teacher', ['rooms']),
    ...mapState({
      currentPageIndex: (state) => state.student.currentPageIndex,
    }),
    ...mapGetters(['isStudentPaced', 'getQuestion']),
    showFullAnswer() {
      return this.$store.state.teacher.showMoreMap[this.currentPageIndex] ?? false
    },
    showResponse() {
      return this.$store.getters['teacher/isResponseShow'](this.currentPageIndex)
    },
    isLockPage() {
      return this.$store.getters['teacher/isLocked'](this.currentPageIndex)
    },
    tpClass() {
      return this.showResponse && this.getQuestion.type !== 'website' && !this.isStudentPaced ? 'content_parent content_parent--border' : 'content_parent'
    },
  },
  created() {},
  mounted() {},
  methods: {
    showCurrentStudent() {
      // console.log("studeng!!!");
    },
    togglePPTList() {
      this.showPPTList = !this.showPPTList
    },
    changePadTab(index) {
      this.padTab = index
    },
  },
}
</script>

<style>
@import url(../../assets/css/dashpage.css);
</style>
