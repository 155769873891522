<template>
  <div class="page" >
    <div class="open-google" @click="openYoutube">
      open youtube
      <i class="flex_c h100 fa fa-share-square-o" style="padding:0 0.3rem;" aria-hidden="true"></i>
    </div>

    <div class="input-class">
      <el-input class="input" placeholder="input youtube video url" v-model="url" @change="refreshVideo()">
        <i class="flex_c h100 fa fa-link" style="font-size:1.2rem;" slot="prefix" aria-hidden="true"></i>
        <i class="flex_c h100 fa fa-refresh theme" style="padding:0 0.3rem;font-size:1.2rem;" slot="suffix" aria-hidden="true" @click="refreshVideo"></i>
      </el-input>
    </div>
    <div id="_player"></div>
    <div class="time--set" v-show="videoId">
      <div class="time">
        Starting time(s):
        <i class="flex_c fa fa-refresh theme" style="padding:0 0.3rem;font-size:1.2rem;" aria-hidden="true" @click="refreshStartTime"></i>
        <div class="time-number-div">
          <el-input class="el-input--inner" v-model="startTime" @change="setStartTime"></el-input>
        </div>
        /{{ endTime }}seconds
      </div>
    </div>
    <div v-loading="loading" class="btn" @click="insert">next</div>
  </div>
</template>

<script>
export default {
  props: {
    nextYoutube: { type: Function }
  },
  data() {
    return {
      loading: false,
      videoId: "",
      ytPlayer: null,
      startTime: 0,
      endTime: 0,
      url: "https://youtu.be/hCVHjyjh3WI",
      delaySetTime: null,
      playerStatus: -1,
      playerStatusInterval: null
    };
  },
  created() {},
  mounted() {
    console.log(this.videoId, "watch videoId");
    window._test = this
  },
  methods: {
    closeYoutubeVideo() {
      this.stopVideo()
      setTimeout(() => {
        // this.ytPlayer = null
        this.videoId = '';
        this.startTime = 0;
        this.endTime = 0;
        this.url = "";
        this.delaySetTime = null
        console.log('closeYoutubeVideo')
      }, 500)
     
      this.playerStatusInterval && clearInterval(this.playerStatusInterval)
    },
    onYTReady(event) {
      console.log(event, 'onYTReady');
      if(this.ytPlayer.getDuration) {
        this.endTime = this.ytPlayer.getDuration();
      }
    },
    onYTStateChange(event) {
      console.log(event, "onYTStateChange");
      const state = event.data
      // if(this.playerStatus == state) return
      // this.playerStatus = state
      if (state == 5 && this.setTimePaly) {
        this.ytPlayer.mute();
        this.ytPlayer.playVideo();
      }
      if (state == 1 && this.setTimePaly) {
        this.ytPlayer.pauseVideo();
        this.setTimePaly = false;
        this.ytPlayer.unMute();
      }
    },
    stopVideo() {
      if(this.ytPlayer && this.ytPlayer.pauseVideo && this.ytPlayer.clearVideo) {
        this.ytPlayer.pauseVideo();
        this.ytPlayer.clearVideo();
      }
    },
    setStartTime() {
      console.log('setStartTime', this.startTime)
      return
      if(this.delaySetTime) clearTimeout(this.delaySetTime)
      this.delaySetTime = setTimeout(() => {
        this.delaySetTime = null
        if(this.ytPlayer.cueVideoByUrl) {
          this.ytPlayer.cueVideoByUrl(this.videoUrl, this.startTime);
          this.ytPlayer.mute();
        }
        this.setTimePaly = true;
      }, 100)
    },

    //显示提示
    slideAlert: function(msg, _type) {
      this.$message({ message: msg, type: _type })
    },
    openYoutube() {
      window.open("https://www.youtube.com/")
    },
    async refreshVideo() {
      //https://youtu.be/hCVHjyjh3WI   https://www.youtube.com/watch?v=hCVHjyjh3WI&t=2187s
      if (this.ytPlayer) this.ytPlayer.destroy()
      if (this.dom) this.dom.remove()
      await this.$forceUpdate()
      this.url = this.url.trim()
      if(!this.url) return
      const ubj = new URL(this.url)
      if (this.url.indexOf("https://youtu.be/") > -1) {
        this.videoId = this.url.split("/").pop()
      } else {
        this.videoId = ubj.searchParams.get('v')
      }
      if (!this.videoId) return
      this.dom = document.createElement("div")
      _player.appendChild(this.dom)
      this.ytPlayer = new YT.Player(this.dom, {
        videoId: this.videoId, width: 640, height: 360,
        events: { onReady: this.onYTReady, onStateChange: this.onYTStateChange }
      })
    },
    refreshStartTime() {
      this.startTime = 0;
    },
    async insert() {
      if (this.loading) return
      if (!this.videoId) return this.refreshVideo()
      this.loading = true
      const url = `https://www.youtube.com/embed/${this.videoId}?enablejsapi=1&rel=0&start=${this.startTime}&origin=${location.origin}`
      await this.nextYoutube(url)
      this.url = "";
      this.loading = false
      this.startTime = 0;
      this.endTime = 0;
    }
  }
};
</script>

<style>
.el-input--inner {
  width: 100px;
}

.time-number-div .el-input__inner {
  border: 0px solid #15c39a;
  background-color: transparent !important;
}

.time-number-div {
  margin-left: 10px;
  display: flex;
  background-color: #efefef;
  border-radius: 2px;
  min-width: 60px;
  align-items: center;
  height: 35px;
}

.time-btn-div {
  display: flex;
  flex-direction: column;
}

.time--set {
  display: flex;
  height: 40px;
}

.time {
  color: #7e7e7e;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.time-btn-flag {
  cursor: pointer;
  margin-right: 3px;
  margin-left: 3px;
}

.page {
  width: 99%;
  height: auto!important;
  padding:0!important;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.open_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  color: #15c39a;
  font-size: 18px;
  cursor: pointer;
}

.el-select .el-input {
  width: 100%;
}

.input-class {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.open-google {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 40px;
  color: white;
  font-size: 14px;
  background-color: #15c39a;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 10px;
}

.input {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 80%;
  border: 1px solid #15c39a;
  border-radius: 6px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
}

iframe {
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 40px;
  color: white;
  font-size: 14px;
  background-color: #15c39a;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 10px;
}
</style>


