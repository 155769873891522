<template>
  <div class="copy_page">
    <div class="content" v-if="tipsItemList && tipsItemList.length > 0">
      <div class="ppt-list">
        <div
          class="ppt-list-item"
          :class="{ active: currentIndex === index }"
          @click="changeIndex(index)"
          v-for="(item, index) in tipsItemList"
          :key="index"
        >
          <pptcontent :url="item.thumbnail_url" />
        </div>

        <div class="footer">
          {{ tipsItemList[currentIndex].index }}/{{ session.pages.length }}
        </div>
      </div>
      <div class="ppt-content">
        <iframe id="classroom-iframe" :src="iframeUrl"></iframe>
      </div>
    </div>
    <div v-else>
      <img src="../../assets/picture/tipsempty.png" width="600" height="400" />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import pptcontent from "../pptcontent.vue";
import PPT from "@/utils/pptConfig";
export default {
  components: {
    pptcontent,
  },
  props: {
    close: {
      type: Function,
    },
    isTeacher: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tipsItemList() {
      if (_.isEmpty(this.session.pages)) return [];
      const newList = this.session.pages.map((d, index) => {
        return { ...d, index: index + 1 };
      });
      //return newList;
      return newList.filter((item) => {
        const { elements = [], items = [] } = item;
        console.log("item",elements,items)
        const tipItemIndex = elements.findIndex((ele) => ele.data.type === "tip");
        let hasCorrectAanswer = false;
        if (items.length > 0) {
          const {
            type,
            data: { showCorrectAnswer },
          } = items[0];
          hasCorrectAanswer = showCorrectAnswer;
        }
        return tipItemIndex > -1 || (items.length > 0);
      });
    },
    currentItemData() {
      return this.tipsItemList[this.currentIndex];
    },
    currentTips() {
      if (this.tipsItemList.length > 0) {
        const { elements } = this.tipsItemList[this.currentIndex];
        const tipItemIndex = elements.findIndex((ele) => ele.type === "tip");
        return elements[tipItemIndex];
      }
      return null;
    },
    ...mapState(['session']),
  },
  watch: {
  },
  data() {
    return {
      currentIndex: 0,
      contenteditable: false,
      rightAnswers: [],
      isDisable: true,
      iframeUrl: '',
      updatedTipData: {},
      canUpload: true
    };
  },
  mounted() {
    this.iframeUrl =`/v2/com/tips`
    this.getMessageFromChild()
  },
  methods: {
    postMessageToChild(data){
        console.log("postMessageToChild")
        let oFrame=document.getElementById('classroom-iframe');
        oFrame.contentWindow.postMessage({
          from: 'classroom',
          event: 'sendtipData',
          // data: data,
          tip: data.elements.find(o => o.data.type === 'tip'),
          options: data.items[0].data.data.options
        },'*')
    },
    getMessageFromChild(){
      window.addEventListener('message',(event)=>{
        console.log("dashTipModal--------listener",event.data)
        if (typeof event.data === 'object') {
          const data = event.data
          switch (data.act) {
            case 'loadTipData':
              this.postMessageToChild(this.currentItemData)
              break;
            default:
              break;
          }
          return
        }
        let data = {}
        try{
          data = JSON.parse(event.data)
        } catch (error) {}
        if( data.from==='form' && data.event === 'tip-loaded'){
          this.postMessageToChild(this.currentItemData);
        }else if( data.from==='form' && data.event === 'updateTip'){
          this.updatedTipData = data.data
          console.log("this.updatedTip",data.data)
          this.updateTip()
        }else if( data.from==='form' && data.event === 'changeAnswer'){
          this.rightAnswers= data.data
          console.log("this.rightAnswers",this.rightAnswers)
          this.handleCheckedChange()
        }else if( data.from==='form' && data.event === 'canUpload'){
          this.canUpload= data.data
          console.log("this.canUpload----",this.canUpload)
        }
      })
    },
    changeIndex(index) {
      if(this.canUpload==false){
        this.$message("Video uploading, please wait!")
        return null
      }
      this.currentIndex = index;
      this.postMessageToChild(this.currentItemData);
    },
    async updateTip() {
      const data = { page_id: this.currentItemData.page_id, tip: this.updatedTipData.tip,urls: this.updatedTipData.urls };
    },
    handleCheckedChange() {
      console.log("answer change: ", this.currentItemData);
      // this.updateSlideCorrectAnswer({
      //   class_id: this.$route.params.id,
      //   page_id: this.currentItemData.page_id,
      //   correct_answer: this.rightAnswers,
      // });
    },
  },
};
</script>

<style scoped>
.copy_page {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  opacity: 1;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}
.add_tip_btn {
  display: flex;
  flex-direction: row;
}
.head_add {
  display: flex;
  flex-direction: row-reverse;
  margin: 10px;
}
.tip_add_text {
  font-size: 20px;
  font-weight: bold;
  color: #28c29b;
  line-height: 28px;
  margin-left: 3px;
  margin-right: 10px;
}
.upload-type-item {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 32px;
}
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.content {
  /* flex: 1; */
  display: flex;

  padding: 0 15px 70px;
  height: 100%;
}
.ppt-list {
  flex: 1;
  height: 100%;
  overflow-y: scroll;
}
.ppt-list-item {
  width: 100%;
  position: relative;
  margin-bottom: 10px;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
  cursor: pointer;
}
.active {
  border: 2px solid #28c29b;
}

.ppt-content {
  flex: 3;
  box-sizing: border-box;
  padding: 0 10px;
  max-height: 100%;
  position: relative;
}
#classroom-iframe {
  z-index: 50;
  border: none;
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
}
.ppt-content-inner {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 1px solid rgba(220, 220, 220, 1);
}
.footer {
  position: absolute;
  left: 10px;
  bottom: 10px;
  height: 32px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  padding: 0 22px;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  color: #fff;
}
</style>
