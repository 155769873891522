<template>
  <!--
<el-form ref="form" :model="formData" :rules="rules" label-width="8rem" v-loading="loading">
-->
  <el-form ref="form" :model="formData" label-width="8rem" v-loading="loading">
    <div class="invite-code">{{ session.sid }}</div>
    <el-form-item :label="getStatus()">
      <!--
      <div>
        <pre>start:{{session.start}}</pre>
        <pre>end:{{session.end}}</pre>
        <pre>status:{{session.status}}</pre>
        <pre>sessionType:{{session.sessionType}}</pre>
        <pre>countdown:{{session.countdown}}</pre>
      </div>
      -->
      <sessionTime :session="session"></sessionTime>
      <!--
      <div class="theme">{{ getStatusAndDuration()?.['state'] }}: {{ getStatusAndDuration()?.['format'] }}</div>
      <div class="theme">Duration: {{ getStatusAndDuration()?.['duration'] }}</div>
      <div>
        {{ new Date(session.start).toLocaleString() }} <span v-if="session.end">- {{ new Date(session.end).toLocaleString() }}</span>
      </div>
      -->
    </el-form-item>
    <el-form-item label="Allocated time" prop="deadline">
      <!--
        :disabled="
          (getStatus() !== 'Scheduled' && session.sessionType == 'student') ||
          session.status == 'close' ||
          countDown.status === 1 ||
          session.countdown?.studentCtrl
        ">
          (getStatus() !== 'Scheduled' && session.sessionType == 'student') ||
          countDown.status === 1 ||
          (isStudentCtrl && session.sessionType !== 'student')
    -->
      <el-radio-group v-model="time_type" @change="checkFn" :disabled="timeTypeDisabled">
        <el-radio :label="0">Off</el-radio>
        <el-radio :label="1">Deadline</el-radio>
        <el-radio :label="2">Countdown</el-radio>
      </el-radio-group>
      <div class="allocate-time" v-show="time_type > 0">
        <div class="allocate-tips">
          <el-alert
            v-show="alertVisible"
            class="my-alert"
            @close="closeAlert"
            title="Students must complete their work within the allocated time"
            type="error" />
        </div>
        <div class="time-type count-down" v-show="time_type === 2">
          <!--
            :disabled="(getStatus() !== 'Scheduled' && session.sessionType == 'student') || countDown.status === 1 || session.countdown?.studentCtrl">
            :disabled="(getStatus() !== 'Scheduled' && session.sessionType == 'student') || countDown.status === 1 || (isStudentCtrl && session.sessionType !== 'student')">
        -->
          <el-select v-model="time_down" placeholder="--Select--" class="my-login-input" :disabled="timeTypeDisabled">
            <el-option v-for="item in timeCounts" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
        <div class="time-type dead-line" v-show="time_type === 1">
          <el-input id="allocateTime" v-model="formData.deadline" :disabled="timeTypeDisabled" placeholder="Select Date..">
            <!--
            <el-button
              v-if="countDown.status == 1"
              @click="deadlineNotEditable = !deadlineNotEditable"
              slot="append"
              :icon="deadlineNotEditable ? 'el-icon-lock' : 'el-icon-unlock'"></el-button>
            -->
          </el-input>
        </div>
      </div>
    </el-form-item>
    <!--
    <el-form-item label="Student-control" v-show="time_type === 2">
      <el-switch @change="handleStudentCtrl" :disabled="session.countdown?.studentCtrl" v-model="isStudentCtrl" active-color="#15C39A" />
      <div class="login-setting-content">
        <div class="main-tips"></div>
        <div class="sub-tips">
          <p>Turn on student-control, students can decide when to start counting down themselves</p>
        </div>
      </div>
    </el-form-item>
    -->
    <el-form-item label="Guest login">
      <el-switch
        @change="handleAnonymousChange"
        :disabled="session.status == 'close' || !allowEditAnonymous || rooms.members.length > 0 || ['taskWorkshop', 'workshop'].includes(session.type)"
        v-model="isGuestLogin"
        active-color="#15C39A" />
      <el-tooltip placement="right">
        <div slot="content">You will not be able to change the guest login setting <br />once any student has entered the session.</div>
        <i class="el-icon-question" style="padding-left: 10px"></i>
      </el-tooltip>
      <div class="login-setting-content">
        <div class="main-tips"></div>
        <div class="sub-tips">
          <p>Turn off to require students to join with their email/ID.</p>
          <p>Turn on to allow anonymous login.</p>
        </div>
      </div>
    </el-form-item>
    <el-form-item label=""> </el-form-item>
    <div class="flex_c">
      <el-button type="primary" :disabled="session.status == 'close'" @click="saveLoginSetting($event)">Confirm</el-button>
      <el-button type="" class="theme" icon="el-icon-link" :disabled="isMentorOrSubstitute" @click="copyLink($event)">Copy link</el-button>
      <el-button v-if="session.zoom?.id" size="small" class="theme flex_c" @click="toZoom">
        <div class="flex_c">
          <img src="../../assets/meetings.png" class="flex_c" style="height: 1.2rem" />
          <img src="../../assets/ZoomLogo.png" class="flex_c" style="height: 0.8rem; padding-left: 0.2rem" />
        </div>
      </el-button>
    </div>
  </el-form>
</template>
<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex'
import sessionTime from '@/components/sessionTime.vue'
import copy from 'copy-to-clipboard'
import PPT from '@/utils/pptConfig'

export default {
  components: {
    sessionTime,
  },
  props: {
    closeLoginModal: {type: Function},
  },
  computed: {
    ...mapState(['session', 'countDown', 'user']),
    ...mapState('teacher', ['rooms']),
    ...mapGetters(['isMentorOrSubstitute']),
    isStundentPaced() {
      return this.session.status === 'student'
    },
    timeTypeDisabled() {
      return (
        (this.session.sessionType == 'live' && (this.session.status == 'close' || (this.session.countdown.type === 2 && !!this.session.countdown.deadline))) ||
        (this.session.sessionType == 'student' && this.getStatus() !== 'Scheduled')
      )
    },
  },
  watch: {
    session: {
      async handler(val) {
        this.time_type = val.countdown.type
        //this.deadlineNotEditable = true
        if (this.time_type === 0) {
          this.formData.deadline = null
          this.saveData.deadline = null
          this.isStudentCtrl = false
          this.time_down = null
          this.initFlatpickr()
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: false,
      //deadlineNotEditable: true,
      dateConf: {
        enableTime: true,
        time_24hr: true,
        minuteIncrement: 1,
        dateFormat: 'Y-m-d H:i:S',
        // formatDate(date, format) {
        //   const ex = new Date().getTimezoneOffset() > 0 ? 86400000 : 0
        //   let nDate = new Date(date.getTime()+new Date().getTimezoneOffset()*60000 - ex)
        //   return flatpickr.formatDate(nDate, format)
        // }
      },
      isGuestLogin: false,
      isStudentCtrl: false,
      room: '--Select--',
      newRoomName: '',
      inputDialog: false,
      time_type: 0,
      options: [
        {value: 0, label: '--Select--'},
        {value: 1, label: 'Deadline mode'},
        {value: 2, label: 'Countdown mode'},
      ],
      time_down: null,
      timeCounts: [
        {value: 15, label: '15 min'},
        {value: 30, label: '30 min'},
        {value: 45, label: '45 min'},
        {value: 60, label: '60 min'},
        {value: 70, label: '70 min'},
      ],
      saveData: {
        deadline: '',
      },
      //lastData: null,
      formData: {
        deadline: '',
      },
      flatpickr: null,
      rules: {
        deadline: {
          message: 'Please select a deadline date',
          trigger: 'blur',
          validator: (rule, val, cb) => {
            if (this.time_type !== 1) return cb()
            if (!val) return cb(true)
            cb()
          },
        },
      },
      allocatedTimeFlag: false,
      allowEditAnonymous: true,
      alertVisible: true,
    }
  },
  async mounted() {
    // 如果之前关闭过alert提示，那边不显示alert
    if (this.session.sid) {
      this.alertVisible = !window.localStorage.getItem(this.session.sid + '_class_setting_alert_visible')
    }

    this.isGuestLogin = !!this.session.guest
    // return
    this.time_type = this.session.countdown?.type ?? 0
    //this.isStudentCtrl = this.session.countdown?.studentCtrl ?? false
    this.isStudentCtrl = this.session.status == 'student' ?? false
    this.initFlatpickr()
    if (this.session.countdown?.type === 2) {
      this.time_down = this.session.countdown.down
      this.formData.deadline = ''
    }
    //this.lastData = JSON.stringify(this.getPostData())
  },
  methods: {
    ...mapActions(['setCountDown']),
    getStatus() {
      const now = new Date()
      let _status = ''
      if (this.session.status == 'close') {
        _status = 'Ended'
      } else if (new Date(this.session.start) >= now) {
        _status = 'Scheduled'
      } else if (new Date(this.session.start) < now) {
        _status = 'Ongoing'
      }

      return _status
    },
    initFlatpickr(reinit) {
      if (this.flatpickr) {
        this.flatpickr.destroy()
        this.flatpickr = null
      }
      const allocateOpt = {
        ...this.dateConf,
        minDate: this.getStatus() == 'Ongoing' ? new Date() : new Date(this.session.start),
        onChange: (selectedDates, dateStr, instance) => {
          this.saveData.deadline = selectedDates[0]
        },
        onReady: (selectedDates) => {
          if (selectedDates[0]) {
            this.saveData.deadline = selectedDates[0]
          }
        },
        onClose: (selectedDates, dateStr, instance) => {
          setTimeout(() => {
            this.initFlatpickr(true)
          }, 500)
        },
      }
      if (!reinit) {
        if (this.session.countdown?.deadline) {
          allocateOpt.defaultDate = new Date(this.session.countdown.deadline)
          if (allocateOpt.minDate.getTime() >= allocateOpt.defaultDate.getTime()) {
            allocateOpt.minDate = allocateOpt.defaultDate
          }
        } else {
          allocateOpt.defaultDate = new Date()
        }
      }
      this.flatpickr = flatpickr('#allocateTime', allocateOpt)
    },
    async copyLink(e) {
      const rs = await this.saveLoginSetting(e, true)
      if (rs) {
        copy(`https://${PPT.isDev ? 'dev.' : PPT.isTest ? 'test.' : ''}classcipe.com/v2/s/${this.session.sid}`)
        this.$message.info('Link copied successfully')
      }
    },
    toZoom() {
      const {start_url, join_url} = this.session.zoom ?? {}
      const url = this.session.uid === this.user._id ? start_url : join_url
      if (!url) return
      window.open(url, 'zoom', 'height=600, width=800, top=0, left=0, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no')
    },
    fixedZone(str, x = 1) {
      return new Date(new Date(str).getTime() + new Date().getTimezoneOffset() * 60000 * x)
    },
    checkFn(f) {
      if (f) return
      this.$refs.form.validate(() => {})
    },
    getPostData() {
      const cd = {type: this.time_type}
      cd.deadline = this.time_type === 1 && this.saveData.deadline ? this.saveData.deadline : null
      cd.down = this.time_type === 2 ? this.time_down : null
      cd.studentCtrl = this.isStudentCtrl
      return cd
    },
    async saveLoginSetting(e, noclose) {
      const data = this.getPostData()

      if (this.time_type === 1 && data.deadline && new Date(this.session.start).getTime() > new Date(data.deadline).getTime()) {
        console.log(this.session.start, data.deadline)
        this.$message.error('The deadline has to be later than the scheduled beginning time')
        return false
      }
      console.warn('form validate', data)
      if ((data.type === 1 && !data.deadline) || (data.type === 2 && !data.down)) {
        this.$message.error(`${data.type === 1 ? 'Deadline' : 'Countdown'} time is required`)
        return false
      }
      this.loading = true
      let sessionStatus = this.session.status
      //console.log(this.time_type, this.session.sessionType, '<=======================================')
      //if ((!this.isStundentPaced && this.time_type === 1) || (this.time_type === 2 && this.isStudentCtrl)) {
      if ((!this.isStundentPaced && this.time_type === 1) || (this.time_type === 2 && this.session.sessionType == 'student')) {
        sessionStatus = 'student'
      }

      //const newData = JSON.stringify(this.getPostData())
      //if (this.lastData != newData) {
      const query = {status: sessionStatus}
      if (!this.timeTypeDisabled) {
        query.countdown = data
      }
      await this.$store.dispatch('upSession', query)
      //this.lastData = newData
      if (this.time_type === 1) {
        this.setCountDown({status: 1})
      }
      //}

      //this.deadlineNotEditable = true
      this.loading = false
      if (!noclose) {
        this.closeLoginModal()
      }
      return true
    },
    hiddenTagInputPopover() {
      if (this.$refs.tagInput) {
        this.$refs.tagInput.hiddenPoppers()
      }
    },
    closeAlert() {
      if (this.session.sid) {
        window.localStorage.setItem(this.session.sid + '_class_setting_alert_visible', 'hidden')
      }
    },
    async handleAnonymousChange(value) {
      if (this.rooms.members.length) return
      if (value) {
        await this.$confirm(
          'Please notice that you will not be able to evaluate the students who logined in anonymously and their learning evidence can not be used for future reports.',
          {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning',
            confirmButtonClass: 'classcipe-btn-primary',
          }
        ).catch(() => {
          this.isGuestLogin = false
        })
      }
      if (this.isGuestLogin !== value) return
      await App.service('session').patch(this.session._id, {guest: this.isGuestLogin})
    },
    async handleStudentCtrl(value) {
      if (value) {
        await this.$confirm(
          'Please notice that once turn on student-control, Individual student decide to start counting down in student-pace mode. Teachers no longer be able to control counting down or turn off student-pace mode unless they choose to end the session.',
          {
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning',
            confirmButtonClass: 'classcipe-btn-primary',
          }
        ).catch(() => {
          this.isStudentCtrl = false
        })
      }
      //if (this.isGuestLogin !== value) return
      //await App.service('session').patch(this.session._id, {guest: this.isGuestLogin})
    },
  },
}
</script>
<style>
.el-input__inner {
  font-size: 1rem;
}
</style>
<style scoped>
.el-radio {
  margin-right: 1rem;
}
.el-form-item {
  margin-bottom: 1rem;
}
.invite-code {
  text-align: center;
  font-size: 40px;
  font-weight: 400;
  color: #15c39a;
  padding-bottom: 10px;
  user-select: all;
  cursor: pointer;
}

.invite-code::selection {
  background: #15c39a;
  color: #fff;
}

.login-setting-content {
  text-align: left;
}

.main-tips {
  font-size: 15px;
  font-weight: 600;
  color: #070707;
  line-height: 25px;
}

.sub-tips {
  padding: 5px 0;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}

.allocate-tips {
  margin-top: 10px;
  text-align: left;
  margin-bottom: 10px;
}
.my-login-input {
  width: 100%;
}
</style>
