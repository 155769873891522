<template>
  <div class="meterial" v-if="isMaterialShow">
    <pptDetail
      :url="pptUrl"
      :teacher="true"
      :defaultShowMeterial="true"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pptDetail from '../pptDetail.vue';
export default {
  components: { pptDetail },
  props: {
    pptUrl: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapGetters(['isMaterialShow']),
  }
};
</script>
<style scoped>
.meterial{
  position: absolute;
  left:0;
  right: 0;
  top:0;
  bottom: 0;
  z-index: 9;
  /* background-color: #e0e0e0; */
}
</style>