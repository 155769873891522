<template>
<div style="overflow-y: auto;overflow-x: hidden;">
  <el-table :data="rooms.teachers" style="width: 100%">
    <el-table-column label="Name" width="300">
      <template slot-scope="scope">
        <div class="flex_l">
          <student-avatar :class="{'-off': !onlineTeachers[scope.row._id]}" :student="scope.row"></student-avatar>
          <div class="flex_c">{{ scope.row.nickname }}</div>
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="name" label="Email">
      <template slot-scope="scope" class="flex_h">
        {{ scope.row.email }}
      </template>
    </el-table-column>
    <div class="flex_v flex_c pd_1" slot="empty">
      <img class="flex q-pa-lg" style="width:15rem;" src="/v2/src/assets/icons/nodata.svg" alt="">
      <div class="flex_c">No data</div>
    </div>
  </el-table>
</div>
</template>
<script>
import { mapState } from 'vuex'
import StudentAvatar from '../common/studentAvatar';
export default {
  components: { StudentAvatar },
  computed: {
    ...mapState('teacher', ['rooms', 'teachers', 'onlineTeachers']),
  }
}
</script>
