<template>
  <div v-show="syncUI.fullPlayUrl" class="flex1 flex_c remotePlay">
    <div class="flex_v" v-loading="loading">
      <el-button class="_close" icon="el-icon-close" @click="$store.dispatch('fullPlayClose')" circle></el-button>
      <!--
      <div class="flex1 flex_c" @click="playFn">Play video on projector</div>
      -->
      <div class="flex1 flex_c">Play audio/video on projector</div>
      <div class="flex_v _bar">
        <div class="flex1 flex_ac">
          <i class="flex fa" :class="playIcon" style="color: #ddd; cursor: pointer" @click="playFn"></i>
          <div class="flex pd_l1">
            {{ playTimeStr(syncUI.fullPlayCurrentTime) }}{{ syncUI.fullPlayDuration ? ' / ' + playTimeStr(syncUI.fullPlayDuration) : '' }}
          </div>
          <div class="flex1"></div>
          <div class="flex_c" @click="onSliderClick">
            <el-slider
              :disabled="unactivated && !isLocal"
              class="flex1"
              style="width: 6rem; padding: 0.6rem"
              :show-tooltip="false"
              v-model="volume"
              :max="100"
              @change="volumeFn"></el-slider>
            <div style="width: 1rem">
              <i class="flex fa" :class="volume > 40 ? 'fa-volume-up' : volume > 0 ? 'fa-volume-down' : 'fa-volume-off'"></i>
            </div>
          </div>
        </div>
        <el-slider
          v-if="syncUI.fullPlayDuration"
          class="flex1"
          :show-tooltip="false"
          v-model="playTime"
          :max="syncUI.fullPlayDuration"
          @change="playTo"></el-slider>
      </div>
    </div>
    <el-dialog  :visible.sync="dialogVisible" width="50%" :append-to-body="true">
      <span>In order to synchronise audio, please go to the projector window and click the unmute button to continue playing.</span>
      <span slot="footer" class="dialog-footer">
        <img src="../../assets/picture/unmute.jpg"  width="100%"/>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {mapState, mapActions} from 'vuex'
export default {
  computed: {
    ...mapState(['syncUI']),
    playIcon() {
      return this.syncUI.fullPlayPaused === false ? 'fa-pause' : 'fa-play'
    },
    isLocal() {
      return this.syncUI.fullPlayUrl && !this.syncUI.fullPlayUrl?.includes('youtube')
    },
  },
  data() {
    return {
      player: null,
      volume: 0,
      playTime: 10,
      loading: false,
      unactivated: true,
      dialogVisible: false,
      initmuted: false,
      lastSt: null,
    }
  },
  watch: {
    'syncUI.fullPlayUrl': {
      handler(o) {
        this.handleFullplay(o)
        if (!o) this.dialogVisible = false
      },
      deep: true,
    },
    'syncUI.fullPlayVolume': {
      handler(o) {
        if (this.initmuted && o > 0) {
          this.unactivated = false
          this.dialogVisible = false
        }
        if (o == 0 && !this.loading) {
          // on youtube video loaded
          this.initmuted = true
        }
        this.volume = o
      },
      deep: true,
    },
    'syncUI.fullPlayCurrentTime': {
      handler(o) {
        console.log(o, 'currentTime', this.loading)
        this.playTime = o
        if (o > 0) this.hideLoading()
      },
      deep: true,
    },
    'syncUI.fullPlayDuration': {
      handler(o) {
        console.log(o, 'duration', this.loading)
        if (o) this.hideLoading()
      },
      deep: true,
    },
  },
  created() {
    this.handleFullplay()
  },
  methods: {
    ...mapActions(['setSyncUI']),
    onSliderClick() {
      if (this.unactivated && !this.isLocal) {
        this.dialogVisible = true
      }
    },
    handleFullplay(o) {
      const url = o ?? this.syncUI.fullPlayUrl
      this.playTime = 0
      this.volume = 0
      this.initmuted = false
      this.unactivated = true
      if (!url) return
      this.loading = true
      clearTimeout(this.lastSt)
      this.lastSt = setTimeout(() => {
        if ((this.loading = true))
          this.$message({
            message: 'Please ensure that the Projector mode is enabled and check if the network environment is stable.',
            duration: 5000,
            type: 'warning',
          })
      }, 5000)
    },
    hideLoading() {
      this.loading = false
      clearTimeout(this.lastSt)
      this.lastSt = null
    },
    volumeFn(o) {
      console.log(o, 'volume')
      this.setSyncUI({fullPlayVolume: o})
    },
    async playFn() {
      await this.setSyncUI({fullPlayPaused: !this.syncUI.fullPlayPaused})
    },
    playTo(val) {
      this.setSyncUI({fullPlaySeek: val})
    },
    playTimeStr(time) {
      time = parseInt(time)
      let sec = time % 60
      let min = parseInt(time / 60)
      if (sec < 10) sec = '0' + sec
      if (min < 10) min = '0' + min
      return `${min}:${sec}`
    },
  },
}
</script>
<style>
.remotePlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2000;
  color: #ddd;
  background-color: #000000dd;
}
.remotePlay > div {
  position: relative;
  height: 15rem;
  width: 60%;
  padding: 1rem 2rem;
  background-color: #333;
  border-radius: 2rem;
  overflow: hidden;
}
.remotePlay .fa {
  font-size: 1.5rem;
}
.remotePlay ._close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 2001;
}
/* .remotePlay ._bar{background: linear-gradient(#00000000, #000000DD);padding-top:2rem;} */
</style>
