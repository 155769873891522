<template>
  <div :class="`switch-header ${showFullAnswer ? '' : 'small'}`">
    <el-tooltip :content="`${showFullAnswer ? 'Show Less' : 'Show More'}`" placement="top">
      <div :class="`full-answer-button ${showFullAnswer && 'active'}`" @click="setFullAnswerState"></div>
    </el-tooltip>
    <template v-if="showFullAnswer">
      <div class="dash-pad-visiable">
        <dash-groups-select />
      </div>
      <div class="dash-pad-visiable pad-switch-content">
        <div :class="`pad-switch-content-item ${padTab === 1 && 'active'}`" @click="changePadTab(1)">Student</div>
        <div :class="`pad-switch-content-item ${padTab === 2 && 'active'}`" @click="changePadTab(2)">Response</div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import dashGroupsSelect from '../dashGroupsSelect.vue'
export default {
  components: { dashGroupsSelect },
  computed: {
    ...mapGetters(['getPageId']),
    ...mapState({
      currentPageIndex: state => state.student.currentPageIndex,
    }),
    showFullAnswer() {
      return this.$store.state.teacher.showMoreMap[this.currentPageIndex] ?? false
    }
  },
  props: {
    padTab: {
      type: Number,
      default: 2,
    },
    changePadTab: {
      type: Function
    },
  },
  methods: {
    ...mapActions("teacher", [
      'showMoreBtn'
    ]),
    setFullAnswerState() {
      this.showMoreBtn(this.currentPageIndex)
    }
  }
}
</script>