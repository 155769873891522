<template>
<div class="roster-detail-list group-list">
  <div class="groups">
    <div ontouchmove="event.preventDefault();" class="-un">
      <div class="-t">Ungrouped students ({{noGroupMembers.length}})</div>
      <classroom-group-student id="gdef" class="-def -items" :studentList="noGroupMembers" :fns="{ toggleAttendance:fns.ToggleAttendance, blockStudent:fns.blockStudent }"></classroom-group-student>
      <p v-if="!noGroupMembers.length">drag here</p>
    </div>
    <div v-for="(group, i) in rooms.groups" :key="i" v-loading="group._loading">
      <div class="-t">
        <div v-if="group._edit"><el-input size="mini" v-loading="editLoading" :id="'_edit'+group._id" :autofocus="true" v-model="groupName" @blur="renameDone(group)"></el-input></div>
        <div v-else>{{group.name}}</div>
        <div>
          <div class="flex_c">({{(groupMembers(group) || []).length}}/{{rooms.groupMax}})</div>
          <el-button style="margin: 0 0.5rem;" type="text" icon="el-icon-edit" @click="rename(group)"></el-button>
          <el-popconfirm title="Confirm to delete the group?"
            confirm-button-text='Done'
            @confirm="rmGroup(group)"
            cancel-button-text='Cancel'>
            <el-button slot="reference" type="text" icon="el-icon-delete"></el-button>
          </el-popconfirm>
        </div>
      </div>
      <classroom-group-student ontouchmove="event.preventDefault();" :id="group._id" :group_id="group._id" 
        class='-items' :group="group" :studentList="groupMembers(group)"
        :fns="{ toggleAttendance:fns.ToggleAttendance, blockStudent:fns.blockStudent }"></classroom-group-student>
      <p :id="'p'+group._id" v-if="_.isEmpty(groupMembers(group))">drag here</p>
    </div>
  </div>
</div>
</template>
<script>
import { mapActions,mapState, mapMutations } from 'vuex'
import ClassroomGroupStudent from './ClassroomGroupStudent';
import StudentAvatar from '../common/studentAvatar';
export default {
  components: { StudentAvatar, ClassroomGroupStudent },
  data() {
    return {
      timeSortAsc: true,
      groupName: '',
      editLoading: false,
    }
  },
  props: {
    fns: {
      type: Object,
      required: true
    },
  },
  computed: {
    ...mapState('teacher', ['teachers', 'rooms']),
    noGroupMembers() {
      const list = []
      const groups = this.rooms.groups.map(v => v._id)
      for (const s of this.rooms.members) {
        if (groups.includes(s.group)) continue
        if (!this.rooms.attend.includes(s._id) || this.rooms.block.includes(s._id)) continue
        list.push(s)
      }
      return list
    },
  },
  methods: {
    ...mapMutations('student', ['clearSelectGroupMap']),
    ...mapActions('teacher', ['upGroup', 'delGroup', 'setGroupMember']),
    groupMembers(group) {
      const list = []
      if (!group._id) return []
      for (const s of this.rooms.members) {
        if (s.group !== group._id) continue
        if (!s || !this.rooms.attend.includes(s._id) || this.rooms.block.includes(s._id)) continue
        list.push(s)
      }
      return _.orderBy(list, !this.timeSortAsc?['name']:['last'], !this.timeSortAsc ? ['asc']:['desc'])
    },
    moveToGroup(group_id, studentId) {
      console.log('move group', group_id,studentId)
      window[group_id].appendChild(window[studentId])
    },
    async renameDone(group) {
      const call = () => {
        delete group._edit
        this.$forceUpdate()
      }
      if (this.groupName===group.name) return call()
      this.editLoading = true
      await this.upGroup({name: this.groupName, _id: group._id})
      this.editLoading = false
      call()
    },
    async rename(group) {
      group._edit = true
      this.groupName = group.name
      await this.$forceUpdate()
      window['_edit'+group._id]?.focus()
    },
    async rmGroup(group) {
      group._loading = true
      await this.$forceUpdate()
      const one = Acan.clone(group)
      delete one._loading
      await this.delGroup(one)
      this.clearSelectGroupMap(group._id)
    },
    async dragUp() {
      await this.$forceUpdate()
      for (const dom of document.querySelectorAll('.group-list .-items')) {
        if (this.drag.ids.includes(dom.id)) continue
        this.drag.ids.push(dom.id)
        this.drag.containers.push(dom)
      }
    },
  },
  watch: {
    rooms: {
      handler(o) {
        this.dragUp()
      },
      deep: true
    }
  },
  mounted() {
    window.drag = this.drag = dragula({})
    this.drag.ids = []
    this.drag.on('drop', async(el, target, source) => {
      console.log('drop')
      if (target.id === source.id) return console.log('id ===')
      let _id = el.id
      const student = this.rooms.members.find(v => v._id === _id)
      if (!student) return source.appendChild(el),console.log('not student', _id, el) // 学生 userId 不存在
      let group_id = target.id
      const group = this.rooms.groups.find(v => v._id === group_id)
      if (group && this.rooms.groupMax <= this.groupMembers(group).length) { // 满员
        source.appendChild(el)
        this.$message('The group is full')
        return
      }
      console.log('drop', student, target.childElementCount, source.childElementCount)
      if (window['p'+target.id]) window['p'+target.id].style.display = target.childElementCount > 0 ? 'none' : ''
      if (window['p'+source.id]) window['p'+source.id].style.display = source.childElementCount > 0 ? 'none' : ''
      if (target.id === 'gdef') group_id = null
      await this.setGroupMember([group_id, _id])
      console.log('setStudent success', _id, student.nickname, group_id)
    })
    this.dragUp()
  }
}
</script>
