<template>
  <div class="teacher_page flex1 h100" style="padding: 50px 0 60px 0">
    <DashHeader
      v-if="session"
      :share="copyUrl"
      :openEvaluate="openEvaluate"
      :showStudents="showStudents"
      :reopenClass="_reopenClass"
      :turnModel="turnModel"
      :confirmModeChange="confirmModeChange" />
    <template v-if="!questionModalVisiable">
      <div class="content">
        <TeacherPPTPage
          v-if="!isDashboard"
          :currentAnswerCount="currentAnswerCount"
          :responseContentList="responseContentList"
          :meterialVisiable="isMaterialShow" />
        <DashboardPage
          v-else
          :currentAnswerCount="currentAnswerCount"
          :responseContentList="responseContentList"
          :responsePercentage="responsePercentage"
          :meterialVisiable="isMaterialShow"
          :overviewModalVisiable="overviewModalVisiable"
          :changePage="pageChange" />
      </div>

      <div class="control">
        <teacherControlPanel
          v-if="session"
          :currentPage="parseInt(currentPageIndex) + 1"
          :isDashboard="isDashboard"
          :changePage="pageChange"
          :turnModel="turnModel"
          :current_response="currentAnswerCount"
          :isClosed="session.status == 'close'"
          :reopenClass="_reopenClass"
          :meterialVisiable="isMaterialShow"
          :showDashTips="showDashTips"
          :showNewPrompt="showNewPrompt"
          :dashTipsModalVisiable="dashTipsModalVisiable" />
      </div>
    </template>

    <guidePrompt v-if="isInitOk" :isDashboard="isDashboard" :guideOkCall="guideOkCall" />

    <CommentModal />

    <el-dialog title="Set feedback failure" :visible.sync="showTimeSetDialog">
      <feedbackTimePanel :mode="mode" :changeFeedbackTimeMode="changeFeedbackTimeMode" />
    </el-dialog>

    <el-dialog title="" :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false" :visible.sync="canceled" :append-to-body="true">
      <div class="dialog_page">
        <div>This session has been cancelled, please schedule another workshop</div>
        <div class="flex_r pd_t1">
          <el-button type="danger" @click="backToClass()">Confirm</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      v-if="isInitOk && isGuideOk && (session.welcome || showShareEvenClose)"
      title="Join at classcipe.com"
      @close="closeCopyDialog"
      :visible="session.welcome || showShareEvenClose"
      :center="true"
      custom-class="custom-dialog"
      top="2vh"
      width="700px">
      <AnonymousLogin :closeLoginModal="closeCopyDialog" />
    </el-dialog>

    <el-dialog
      title="Reopen session"
      @close="closeReopenDialog"
      v-if="showReopenDialog"
      :visible="showReopenDialog"
      :center="true"
      custom-class="custom-dialog"
      top="2vh"
      width="700px">
      <ReopenDialog :close="closeReopenDialog" />
    </el-dialog>

    <el-drawer :with-header="false" :visible.sync="showClassroomRoster" direction="ltr" size="90%">
      <!--
      <classroom-roster :openEvaluate="openEvaluate" v-show="showClassroomRoster" v-if="isGuideOk" @close="showClassroomRoster = false" />
      -->
      <classroom-roster :openEvaluate="openEvaluate" v-show="showClassroomRoster" @close="showClassroomRoster = false" />
    </el-drawer>

    <!--
    <studentAudioItem class="flex1" v-else-if="getQuestion.type === 'media'" v-bind="$attrs" :addMediaAnswer="addMediaAnswer" />
    <el-dialog :visible.sync="showNewPromptDialog" fullscreen>
      <new-prompt-page style="height: 900px" :addPPTItem="addPPTItem" :closeBtn="closeNewPrompt" />
    </el-dialog>
    -->
    <new-prompt-page
      v-if="showNewPromptDialog"
      class="flex1"
      :currentPage="parseInt(currentPageIndex) + 1"
      :addPPTItem="addPPTItem"
      :closeBtn="closeNewPrompt" />
    <el-dialog :visible.sync="showStudentPacedNotePage" :show-close="false" :close-on-click-modal="false" width="80%">
      <ModalStudentModeOn v-if="showStudentPacedNotePage" :confirmModeChange="confirmModeChange" :cancelModeChange="cancelModeChange" />
    </el-dialog>

    <el-drawer direction="ltr" :visible.sync="dashTipsModalVisiable" size="90%" :withHeader="false">
      <!-- <el-dialog :visible.sync="dashTipsModalVisiable" width="80%"> -->
      <dashTipsModal :close="showDashTips" :isTeacher="true" />
      <!-- </el-dialog> -->
    </el-drawer>

    <bigPreview :isShowResponse="showResponse" />
    <preview-metarial-item :meterialVisiable="isMaterialShow" />

    <el-drawer :destroy-on-close="true" size="95%" :visible.sync="drawerEvaluate" direction="ltr" :withHeader="false" :wrapperClosable="false">
      <el-button icon="el-icon-close" style="position: absolute; top: 1rem; right: -1.5rem; z-index: 1000" circle @click="closeEvaluate"></el-button>
      <iframe class="flex1" style="margin: 0" :src="evaluationUrl" frameborder="0"></iframe>
    </el-drawer>
    <fullPlay v-if="($route.name === 'd' && !syncUI.fullPlayOn) || $route.name === 't'" />
    <remotePlay v-if="$route.name === 'd' && syncUI.fullPlayOn === 'projector'" />
    <fullIframe />
    <fullDoc />
    <drawTop v-if="isGuideOk && syncUI.drawUrl" />
  </div>
</template>

<script>
import copy from 'copy-to-clipboard'
import drawTop from '@/components/drawTop.vue'
import PPT from '@/utils/pptConfig'
import {getStore} from '@/utils/localStore'
import {showLoading, hideLoading, showToast} from '../utils/loading'
import fullPlay from '@/components/fullPlay.vue'
import fullIframe from '@/components/fullIframe.vue'
import fullDoc from '@/components/fullDoc.vue'
import remotePlay from '@/components/common/remotePlay.vue'
import teacherControlPanel from '../components/teacher/teacherControlPanel'
import CommentModal from '@/components/teacher/commentModal.vue'
import TeacherPPTPage from '@/components/teacher/teacherPPTPage.vue'
import DashboardPage from '@/components/teacher/dashboardPage.vue'
import feedbackTimePanel from '../components/teacher/feedbackTimePanel'
import AnonymousLogin from '../components/teacher/AnonymousLogin.vue'
import ReopenDialog from '../components/teacher/ReopenDialog.vue'
import dashCopyDialog from '../components/teacher/dashCopyDialog'
import StudentsQsModal from '@/components/teacher/studentsQsModal.vue'
import guidePrompt from '@/components/guidePrompt.vue'
import DashHeader from '@/components/teacher/dashHeader.vue'
import dashTipsModal from '@/components/teacher/dashTipsModalV3.vue'
import {mapActions, mapState, mapMutations, mapGetters} from 'vuex'
import NewPromptPage from '@/components/teacher/newPromptPage.vue'
import bigPreview from '@/components/common/bigPreview.vue'
import ClassroomRoster from '@/components/teacher/ClassroomRoster'
import PreviewMetarialItem from '@/components/drag/previewMetarialItem.vue'

import ModalStudentModeOn from '@/components/teacher/modals/StudentModeOn.vue'

export default {
  components: {
    guidePrompt,
    ClassroomRoster,
    teacherControlPanel,
    CommentModal,
    TeacherPPTPage,
    DashboardPage,
    // welcomeModal,
    feedbackTimePanel,
    AnonymousLogin,
    ReopenDialog,
    StudentsQsModal,
    DashHeader,
    dashCopyDialog,
    dashTipsModal,
    NewPromptPage,
    bigPreview,
    PreviewMetarialItem,
    fullPlay,
    remotePlay,
    fullIframe,
    fullDoc,
    drawTop,
    ModalStudentModeOn,
  },
  data() {
    return {
      isInitOk: false,
      rosterLoading: false,
      studentCounts: 0,
      currentSo: null,
      currentAnswerCount: 0,
      canceled: false,
      name: '',
      googleLoginStatus: 0, // 0 未知， -1 登录， 1 登录
      responseContentList: [],
      token: '',
      isLocked: false,
      isDashboard: false,
      responsePercentage: [],
      isFocus: [],
      welcomeModalVis: false,
      welcomeModalShowed: false,
      showTimeSetDialog: false,
      copyLinkBtnString: '',
      firstCloseCopyLinkDialog: true,
      mode: 1,
      questionModalVisiable: false,
      markupslist: [], // ppt comment列表
      overviewModalVisiable: false,
      dashTipsModalVisiable: false,
      showNewPromptDialog: false,
      showReopenDialog: false,
      inputDialog: false,
      sendControlDelay: null,
      showClassroomRoster: false,
      showNetErrorDelay: null,
      drawerEvaluate: false,
      evaluationUrl: '',
      showShareEvenClose: false,

      // turn mode modal
      showStudentPacedNotePage: false,
      isGuideOk: false,
    }
  },
  created() {
    window._this = this
    window._state = this.$store.state
    window._store = this.$store
    this.$store.commit('setClassId', {id: this.$route.params.id, name: this.$route.name})
  },
  async mounted() {
    console.log('Init start...')
    localStorage.setItem('referrer', document.referrer)
    window._st = Date.now()
    window.classId = this.class_id
    this.isDashboard = this.$route.name === 'd'
    console.log('mounted', this.class_id)
    this.token = this.$route.query.token || (await App.authentication.getAccessToken()) || ''
    await this.$store.dispatch('getClassInfo')
    App.service('session').removeAllListeners(['patched', 'removed'])
    App.service('session')
      .on('patched', (rs) => {
        if (this.session._id !== rs._id) return console.warn('session id invalid:', rs)
        commit('patchSession', rs)
      })
      .on('removed', (rs) => {
        if (rs.sid === this.session.sid) {
          this.canceled = true
        }
      })
    const {commit} = this.$store
    App.service('response').removeAllListeners(['created', 'patched', 'removed'])
    App.service('response')
      .on('created', (rs) => {
        commit('student/setAllAnswerdListOne', rs)
      })
      .on('patched', (rs) => {
        commit('student/setAllAnswerdListOne', rs)
      })
      .on('removed', (rs) => {
        commit('student/deleteOnAnswerById', rs._id)
      })
    App.service('auth').removeAllListeners(['patched'])
    App.service('auth').on('patched', (rs) => {
      const {id, on, from, user, role} = rs
      if (id === 'syncUI') return commit('setSyncUI', rs, {root: true})
      if (user) {
        console.log(on, from, role, user.nickname, rs)
        const online = on === 'connect' ? true : false
        if (role === 'teacher') return commit('teacher/setTeacher', user), commit('teacher/setOnlineTeacher', [[user._id], online])
        commit('teacher/setOnlineList', [[user._id], online])
        return
      }
      if (this.$route.name === 'p' && this.isStudentPaced) return
      try {
        if (rs.emit) EventBus.$emit(rs.emit, rs.data)
        if (rs.method && this[rs.method]) this[rs.method](rs.data)
        if (rs.commit) commit(rs.commit, rs.data)
      } catch (error) {}
    })
    await this.initWithToken()
    await this.$store.dispatch('getClassInfo')
    console.log(`Init ok: ${Date.now() - _st}ms`, this.isInitOk, this.isGuideOk)
    if (this.$route.query.reopen) this._reopenClass() // 重开课堂通知
    window.teachers = this.teachers
    window.groups = this.groups
    if (!this.session.drawkey) {
      let drawkey = await Fn.drawKey()
      await this.$store.dispatch('upSession', {drawkey})
    }
    await this.$store.dispatch('getClassDetail')
    await this.$store.dispatch('getTakeaway')
  },
  computed: {
    ...mapState(['session', 'class_id', 'syncUI']),
    ...mapState('student', ['currentPageIndex']),
    ...mapState('teacher', ['students', 'teachers', 'groups']),
    ...mapGetters(['getPageId', 'getPageNum', 'getQuestion', 'isMaterialShow', 'isStudentPaced', 'isMentorOrSubstitute']),
    showResponse() {
      return this.$store.getters['teacher/isResponseShow'](this.currentPageIndex)
    },
  },
  watch: {
    currentPageIndex() {
      this.getPageData()
      if (!this.isInitOk) return 0
    },
  },
  beforeRouteEnter(to, from, next) {
    window.isTeacherPage = true
    next((vm) => {})
  },
  methods: {
    ...mapMutations('teacher', ['hidePageMaterial']),
    ...mapActions('teacher', ['initStudent', 'setFeedBackList', 'setFeedBackAnswerIds']),
    ...mapActions('student', ['setStudentPageIndex', 'setAllAnswerdList']),
    closeNewPrompt() {
      this.showNewPromptDialog = false
    },
    showNewPrompt() {
      this.showNewPromptDialog = true
    },
    addPPTItem(item) {
      this.$store.commit('patchSession', item)
      this.pageChange(parseInt(this.currentPageIndex) + 2)
      this.$store.dispatch('initMaterials')
      hideLoading()
    },
    showInputDialog() {
      console.log('showInputDialog')
      this.inputDialog = true
    },
    showDashTips() {
      this.dashTipsModalVisiable = !this.dashTipsModalVisiable
    },
    closeEvaluate() {
      this.drawerEvaluate = false
    },
    openEvaluate() {
      if (this.evaluationUrl) this.drawerEvaluate = true
    },
    // 检查token
    async initWithToken() {
      showLoading()
      if (!this.token) return this.goToLogin()
      const {logout, authentication, user} = await App.authenticate(
        {strategy: 'jwt', accessToken: this.token},
        {query: {sid: this.class_id, role: 'teacher'}}
      ).catch((err) => {
        console.log(err)
        return {logout: true}
      })
      if (logout) return this.goToLogin()
      if (authentication) {
        Acan.url('token', null)
        if (Acan.url('status') === 'student' && this.session.status !== 'student') {
          await this.$store.dispatch('upSession', {status: 'student'})
          Acan.url('status', null)
        }
      }
      this.name = window.currentNickname = user?.nickname
      window.uid = user?.email
      window.currentUserId = user?._id
      window.Auser = user
      window.class_id = this.class_id
      await Promise.all([this.initStudent(user), this.getAllSlides()])
      await this.$store.dispatch('initPageConf')
      setTimeout(() => {
        this.isInitOk = true
        if (this.$route.query.roster) {
          this.showStudents()
        }
        hideLoading()
      }, 100)
    },
    async guideOkCall() {
      console.warn('guideOkCall')
      this.isGuideOk = true
    },
    async guideCall() {
      if (this.isGuideOk) return
      await sleep(100)
      return this.guideCall()
    },
    goToLogin() {
      const url =
        `https://${PPT.isDev ? 'dev' : 'my'}.classcipe.com/v2/login?from=roomTeacher&role=teacher&redirect=` +
        encodeURIComponent(location.origin + location.pathname)
      location.href = url
    },
    pageChange(value, notSend) {
      console.log('setStudentPageIndex pageChange', value)
      this.giveFocus(parseInt(value) - 1, notSend)
      return
    },
    getPageData() {
      this.questionModalVisiable = false //与控制面板中的comment显示与否的按钮保持同步
      this.getItemData()
    },
    // 老师控制分页
    async sendPageControl() {
      App.service('auth').patch('syncTeacher', {commit: 'student/setCurrentPageIndex', data: this.currentPageIndex})
    },
    async getAllSlides() {
      // 初始化评论数据
      await Promise.all([
        App.service('comments')
          .find({query: {sid: this.class_id, $limit: 1000}})
          .then((rs) => {
            if (rs) this.setFeedBackList(rs.data)
            this.setFeedBackAnswerIds(rs.data.map((v) => v.rid))
          }),
        App.service('response')
          .find({query: {sid: this.class_id, $limit: 2000}})
          .then((rs) => {
            this.setAllAnswerdList(rs.data)
          }),
        this.$store.dispatch('initMaterials'),
      ])
      this.getItemData()
      this.session.pages?.map((o, i) => {
        this.responsePercentage[i] = 0
      })
      this.isFocus[this.currentPageIndex] = true
    },
    getItemData() {
      this.$nextTick(() => {
        if (!this.getQuestion) return
        this.getResponeCount()
      })
    },
    getCurrentPageAnswer() {
      const {type, page} = this.getQuestion
      let list = this.$store.state.student.allAnswerList.filter((item) => item.page === page)
      return list
      /*
      if (type !== 'comment') {
      } else {
        // comment remark 特殊，数据不在answer内
        let list = this.$store.state.remark.allRemarks.filter((item) => item.page_id === page_id)
        return list
      }*/
    },
    getResponeCount(f) {
      if (f || this.getQuestion) {
        const list = this.getCurrentPageAnswer()
        this.currentAnswerCount = list.length
        this.responseContentList = list
        let count = 0
        let id = '-1'
        for (let i = 0; i < list.length; i++) {
          if (id != list[i].user_id) {
            count++
            id = list[i].user_id
          }
        }

        if (this.studentCounts < count) {
          this.studentCounts = count
        }
        if (count == 0) {
          this.responsePercentage[this.currentPageIndex] = 0
        } else {
          this.responsePercentage[this.currentPageIndex] = (count * 100) / this.studentCounts
        }
      } else {
        this.currentAnswerCount = 0
      }
    },
    copyUrl() {
      if (this.session.status === 'close') {
        this.showShareEvenClose = true
        if (!this.isMentorOrSubstitute) {
          this.$message.info({message: 'Please reopen session first', customClass: 'z-max'})
        }
      } else {
        this.$store.dispatch('upSession', {welcome: true})
      }
    },
    closeReopenDialog() {
      this.showReopenDialog = false
    },
    closeCopyDialog() {
      console.warn('close copy url')
      if (this.session.status === 'close' && this.showShareEvenClose) {
        this.showShareEvenClose = false
        return
      }
      if (this.session.welcome) this.$store.dispatch('upSession', {welcome: false})
      this.showWelcome()
    },
    showWelcome() {
      const welcomesetting = getStore('welcomesetting')
      if (!this.welcomeModalShowed && !this.isDashboard && !welcomesetting) {
        this.welcomeModalShowed = true
        this.welcomeModalVis = true
      }
    },
    hideWelcome() {
      this.welcomeModalVis = false
    },
    getBtnString() {
      if (this.$store.getters['teacher/onlineStudentCount'] == 0) {
        this.copyLinkBtnString = 'Waiting for students..'
      } else if (this.currentPageIndex == 0) {
        this.copyLinkBtnString = 'Start Class'
      } else {
        this.copyLinkBtnString = 'Continue'
      }
      return this.copyLinkBtnString
    },
    async turnModel() {
      if (this.session.status === 'student') {
        await this.$store.dispatch('upSession', {status: 'live'})
        this.sendModeChangeCommend()
      } else {
        this.showStudentPacedNotePage = true
      }
    },
    async confirmModeChange() {
      await this.$store.dispatch('upSession', {status: 'student'})
      this.sendModeChangeCommend()
      this.showStudentPacedNotePage = false
    },
    cancelModeChange() {
      this.showStudentPacedNotePage = false
    },
    sendModeChangeCommend() {
      if (this.session.status !== 'live') return
      // 如果从学生模式切换到老师模式，则通知学生进行页面切换操作
      this.sendPageControl()
      if (this.isMaterialShow && this.$route.name === 'd') {
        this.$store.dispatch('syncTeacher', {commit: 'teacher/showPageMaterial', data: [this.currentPageIndex, this.getPageId]})
        this.$store.dispatch('syncTeacher', {commit: 'setMaterialSelect', to: 'teacher', data: this.$store.state.materialSelect})
      }
    },
    open() {
      var strWindowFeatures = 'width=1200,height=750,menubar=yes,resizable=yes,scrollbars=true,status=true,top=100,left=200'
      window.open(`/d/${this.class_id}?p=${this.currentPageIndex}`, '_blank', strWindowFeatures)
    },
    //重新开启课堂
    async _reopenClass() {
      //this.showReopenDialog = true
      await this.$store.dispatch('upSession', {status: 'live'})
      this.$message.info({message: 'Session reopened', customClass: 'z-max'})
    },
    //显示当前的学生
    async showStudents(tab) {
      showLoading()
      await this.$store.dispatch('getClassInfo')
      localStorage.setItem('RoomRoster:Tab', tab || 'student')
      hideLoading()
      this.showClassroomRoster = true
    },
    closeStudents() {
      this.showClassroomRoster = false
    },
    giveFocus(index, notSend) {
      if (isNaN(index)) return false
      if (this.currentPageIndex === index) return false
      console.log(index, notSend, 'giveFocus', this.session.status)
      if (index != this.currentPageIndex) {
        this.setStudentPageIndex(index)
        this.getResponeCount()
      }
      if (!notSend && this.session.status !== 'student') this.sendPageControl()
      for (let i = 0; i < this.getPageNum; i++) {
        this.isFocus[i] = i == index
      }
    },
    changeFeedbackTimeMode(index) {
      this.mode = index
    },
    backToClass() {
      location.href = `https://${PPT.isDev ? 'dev' : 'www'}.classcipe.com`
    },
  },
}
</script>

<style lang="scss">
@import url(../assets/css/teacher.scss);
</style>
