<template>
  <div class="page">
    <div class="item">
      <svg
        v-if="mode != 0"
        @click="changeModel(0)"
        t="1621404675016"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1875"
        width="20"
        height="20"
      >
        <path
          d="M511.8976 991.0272c-64.7168 0-127.3856-12.6976-186.4704-37.6832-57.0368-24.1664-108.3392-58.6752-152.2688-102.7072-44.032-44.032-78.5408-95.232-102.7072-152.2688C45.4656 639.2832 32.768 576.512 32.768 511.7952c0-64.7168 12.6976-127.3856 37.6832-186.4704 24.1664-57.0368 58.6752-108.3392 102.7072-152.2688 44.032-44.032 95.232-78.5408 152.2688-102.7072 59.0848-24.9856 121.856-37.6832 186.4704-37.6832 64.7168 0 127.3856 12.6976 186.4704 37.6832 57.0368 24.1664 108.3392 58.6752 152.2688 102.7072 44.032 44.032 78.5408 95.232 102.7072 152.2688 24.9856 59.0848 37.6832 121.856 37.6832 186.4704 0 64.7168-12.6976 127.3856-37.6832 186.4704-24.1664 57.0368-58.6752 108.3392-102.7072 152.2688-44.032 44.032-95.232 78.5408-152.2688 102.7072C639.3856 978.3296 576.6144 991.0272 511.8976 991.0272L511.8976 991.0272zM511.8976 71.3728c-59.4944 0-117.1456 11.6736-171.4176 34.6112-52.4288 22.2208-99.5328 53.9648-139.9808 94.4128-40.448 40.448-72.192 87.552-94.4128 139.9808-22.9376 54.272-34.6112 111.9232-34.6112 171.4176 0 59.4944 11.6736 117.1456 34.6112 171.4176 22.2208 52.4288 53.9648 99.5328 94.4128 139.9808 40.448 40.448 87.552 72.192 139.9808 94.4128 54.272 22.9376 111.9232 34.6112 171.4176 34.6112 59.4944 0 117.1456-11.6736 171.4176-34.6112 52.4288-22.2208 99.5328-53.9648 139.9808-94.4128 40.448-40.448 72.192-87.552 94.4128-139.9808 22.9376-54.272 34.6112-111.9232 34.6112-171.4176 0-59.4944-11.6736-117.1456-34.6112-171.4176-22.2208-52.4288-53.9648-99.5328-94.4128-139.9808-40.448-40.448-87.552-72.192-139.9808-94.4128C629.0432 83.0464 571.392 71.3728 511.8976 71.3728L511.8976 71.3728zM511.8976 71.3728"
          p-id="1876"
          fill="#1296db"
        />
      </svg>

      <svg
        @click="changeModel(-1)"
        v-if="mode == 0"
        t="1621404751718"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2070"
        width="20"
        height="20"
      >
        <path
          d="M512 981.333333c259.2 0 469.333333-210.133333 469.333333-469.333333S771.2 42.666667 512 42.666667 42.666667 252.8 42.666667 512s210.133333 469.333333 469.333333 469.333333z m0-64C288.149333 917.333333 106.666667 735.850667 106.666667 512S288.149333 106.666667 512 106.666667s405.333333 181.482667 405.333333 405.333333-181.482667 405.333333-405.333333 405.333333z"
          p-id="2071"
          fill="#1296db"
        />
        <path
          d="M512 512m-256 0a256 256 0 1 0 512 0 256 256 0 1 0-512 0Z"
          p-id="2072"
          fill="#1296db"
        />
      </svg>
      <div>Not set</div>
    </div>

    <div class="item">
      <svg
        v-if="mode != 1"
        @click="changeModel(1)"
        t="1621404675016"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1875"
        width="20"
        height="20"
      >
        <path
          d="M511.8976 991.0272c-64.7168 0-127.3856-12.6976-186.4704-37.6832-57.0368-24.1664-108.3392-58.6752-152.2688-102.7072-44.032-44.032-78.5408-95.232-102.7072-152.2688C45.4656 639.2832 32.768 576.512 32.768 511.7952c0-64.7168 12.6976-127.3856 37.6832-186.4704 24.1664-57.0368 58.6752-108.3392 102.7072-152.2688 44.032-44.032 95.232-78.5408 152.2688-102.7072 59.0848-24.9856 121.856-37.6832 186.4704-37.6832 64.7168 0 127.3856 12.6976 186.4704 37.6832 57.0368 24.1664 108.3392 58.6752 152.2688 102.7072 44.032 44.032 78.5408 95.232 102.7072 152.2688 24.9856 59.0848 37.6832 121.856 37.6832 186.4704 0 64.7168-12.6976 127.3856-37.6832 186.4704-24.1664 57.0368-58.6752 108.3392-102.7072 152.2688-44.032 44.032-95.232 78.5408-152.2688 102.7072C639.3856 978.3296 576.6144 991.0272 511.8976 991.0272L511.8976 991.0272zM511.8976 71.3728c-59.4944 0-117.1456 11.6736-171.4176 34.6112-52.4288 22.2208-99.5328 53.9648-139.9808 94.4128-40.448 40.448-72.192 87.552-94.4128 139.9808-22.9376 54.272-34.6112 111.9232-34.6112 171.4176 0 59.4944 11.6736 117.1456 34.6112 171.4176 22.2208 52.4288 53.9648 99.5328 94.4128 139.9808 40.448 40.448 87.552 72.192 139.9808 94.4128 54.272 22.9376 111.9232 34.6112 171.4176 34.6112 59.4944 0 117.1456-11.6736 171.4176-34.6112 52.4288-22.2208 99.5328-53.9648 139.9808-94.4128 40.448-40.448 72.192-87.552 94.4128-139.9808 22.9376-54.272 34.6112-111.9232 34.6112-171.4176 0-59.4944-11.6736-117.1456-34.6112-171.4176-22.2208-52.4288-53.9648-99.5328-94.4128-139.9808-40.448-40.448-87.552-72.192-139.9808-94.4128C629.0432 83.0464 571.392 71.3728 511.8976 71.3728L511.8976 71.3728zM511.8976 71.3728"
          p-id="1876"
          fill="#1296db"
        />
      </svg>

      <svg
        @click="changeModel(-1)"
        v-if="mode == 1"
        t="1621404751718"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2070"
        width="20"
        height="20"
      >
        <path
          d="M512 981.333333c259.2 0 469.333333-210.133333 469.333333-469.333333S771.2 42.666667 512 42.666667 42.666667 252.8 42.666667 512s210.133333 469.333333 469.333333 469.333333z m0-64C288.149333 917.333333 106.666667 735.850667 106.666667 512S288.149333 106.666667 512 106.666667s405.333333 181.482667 405.333333 405.333333-181.482667 405.333333-405.333333 405.333333z"
          p-id="2071"
          fill="#1296db"
        />
        <path
          d="M512 512m-256 0a256 256 0 1 0 512 0 256 256 0 1 0-512 0Z"
          p-id="2072"
          fill="#1296db"
        />
      </svg>
      <div>Deadline mode</div>
    </div>
    <div class="item">
      <div :class="mode == 1 ? 'text' : 'text text--disable'">Deadline</div>
      <el-date-picker
        v-model="deadline"
        format="yyyy-MM-dd HH:mm"
        placeholder="Set deadline"
        type="datetime"
        :picker-options="pickerOptionsStart"
        :disabled="mode != 1"
      />
    </div>

    <div class="item">
      <svg
        v-if="mode != 2"
        @click="changeModel(2)"
        t="1621404675016"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1875"
        width="20"
        height="20"
      >
        <path
          d="M511.8976 991.0272c-64.7168 0-127.3856-12.6976-186.4704-37.6832-57.0368-24.1664-108.3392-58.6752-152.2688-102.7072-44.032-44.032-78.5408-95.232-102.7072-152.2688C45.4656 639.2832 32.768 576.512 32.768 511.7952c0-64.7168 12.6976-127.3856 37.6832-186.4704 24.1664-57.0368 58.6752-108.3392 102.7072-152.2688 44.032-44.032 95.232-78.5408 152.2688-102.7072 59.0848-24.9856 121.856-37.6832 186.4704-37.6832 64.7168 0 127.3856 12.6976 186.4704 37.6832 57.0368 24.1664 108.3392 58.6752 152.2688 102.7072 44.032 44.032 78.5408 95.232 102.7072 152.2688 24.9856 59.0848 37.6832 121.856 37.6832 186.4704 0 64.7168-12.6976 127.3856-37.6832 186.4704-24.1664 57.0368-58.6752 108.3392-102.7072 152.2688-44.032 44.032-95.232 78.5408-152.2688 102.7072C639.3856 978.3296 576.6144 991.0272 511.8976 991.0272L511.8976 991.0272zM511.8976 71.3728c-59.4944 0-117.1456 11.6736-171.4176 34.6112-52.4288 22.2208-99.5328 53.9648-139.9808 94.4128-40.448 40.448-72.192 87.552-94.4128 139.9808-22.9376 54.272-34.6112 111.9232-34.6112 171.4176 0 59.4944 11.6736 117.1456 34.6112 171.4176 22.2208 52.4288 53.9648 99.5328 94.4128 139.9808 40.448 40.448 87.552 72.192 139.9808 94.4128 54.272 22.9376 111.9232 34.6112 171.4176 34.6112 59.4944 0 117.1456-11.6736 171.4176-34.6112 52.4288-22.2208 99.5328-53.9648 139.9808-94.4128 40.448-40.448 72.192-87.552 94.4128-139.9808 22.9376-54.272 34.6112-111.9232 34.6112-171.4176 0-59.4944-11.6736-117.1456-34.6112-171.4176-22.2208-52.4288-53.9648-99.5328-94.4128-139.9808-40.448-40.448-87.552-72.192-139.9808-94.4128C629.0432 83.0464 571.392 71.3728 511.8976 71.3728L511.8976 71.3728zM511.8976 71.3728"
          p-id="1876"
          fill="#1296db"
        />
      </svg>

      <svg
        @click="changeModel(-1)"
        v-if="mode == 2"
        t="1621404751718"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="2070"
        width="20"
        height="20"
      >
        <path
          d="M512 981.333333c259.2 0 469.333333-210.133333 469.333333-469.333333S771.2 42.666667 512 42.666667 42.666667 252.8 42.666667 512s210.133333 469.333333 469.333333 469.333333z m0-64C288.149333 917.333333 106.666667 735.850667 106.666667 512S288.149333 106.666667 512 106.666667s405.333333 181.482667 405.333333 405.333333-181.482667 405.333333-405.333333 405.333333z"
          p-id="2071"
          fill="#1296db"
        />
        <path
          d="M512 512m-256 0a256 256 0 1 0 512 0 256 256 0 1 0-512 0Z"
          p-id="2072"
          fill="#1296db"
        />
      </svg>
      <div>Countdown mode</div>
    </div>
    <div class="item">
      <div :class="mode == 2 ? 'text' : 'text text--disable'">
        Term of validity
      </div>
      <el-select
        v-model="value"
        placeholder="Select a time"
        :disabled="mode != 2"
      >
        <el-option
          v-for="item in times"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>

    <div class="item center">
      <div class="btn_class">
        Detemine
      </div>
    </div>
  </div>
</template>


<style scoped>
.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.text {
  margin-right: 10px;
  font-size: 15px;
}
.text--disable {
  color: #afafaf;
}
.icon {
  cursor: pointer;
  margin-right: 10px;
}
.item {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
}
.center {
  justify-content: center;
}
.btn_class {
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1296db;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}
.btn_class:hover {
  background-color: #3087b7;
}
</style>

<script>
export default {
  props: {
    mode: {
      type: Number,
      default: 1,
    },
    changeFeedbackTimeMode: {
      type: Function,
    },
    confirm: {
      type: Function,
    },
  },
  data() {
    return {
      times: [
        { value: 15, label: "15  min" },
        { value: 30, label: "30  min" },
        { value: 45, label: "45  min" },
        { value: 60, label: "60  min" },
        { value: 70, label: "70  min" },
      ],
      value: "",
      deadline: "",
      pickerOptionsStart: {
        disabledDate: (time) => {
          let date = Date.now();
          //- 8.64e7
          return time.getTime() < Date.now() - 8.64e7; /*今天及以后*/
          // return (
          //   time.getTime() > Date.now() - 8.64e6
          // ); /*今天及之前，注意数字不一样*/
        },
      },
    };
  },
  methods: {
    changeModel(index) {
      this.changeFeedbackTimeMode(index);
    },
  },
};
</script>