<template>
  <div v-show="fullIframe.url" class="flex1 h100 w100" style="position: fixed;top: 0;bottom: 0;left: 0;right: 0;z-index: 4001;">
    <iframe :src="viewUrl" frameborder="0" class="flex1 h100 w100"></iframe>
  </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
  computed: {
    ...mapState(['fullIframe']),
    viewUrl() {
      if (!this.fullIframe.url) return ''
      console.log(this.fullIframe.url)
      return `https://view.officeapps.live.com/op/embed.aspx?src=${this.fullIframe.url}`
    }
  },
  beforeDestroy() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>
