<template>
<div>
  <div v-for="(student) in studentList" :key="student._id" :id="student._id">
    <div class="flex-center" style="position: relative">
      <student-avatar :class="{'-off': !onlineList[student._id]}" :student="student"></student-avatar>
      <div class="-name text-over" :class="{blockline:student.block}">{{student.nickname}}</div>
    </div>
    <el-dropdown>
      <span class="el-dropdown-link">
        <i class="el-icon-more el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item icon="el-icon-warning" @click.native="fns.blockStudent(student._id, false)">Block</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</div>
</template>
<script>
import { mapState } from 'vuex'
import StudentAvatar from '../common/studentAvatar';
export default {
  components: { StudentAvatar },
  computed: {
    ...mapState('teacher', ['onlineList'])
  },
  props: {
    studentList: { type: Array, required: true },
    group_id: { type: String, required: false },
    group: { type: Object, required: false },
    fns: { type: Object, required: true },
  }
}
</script>