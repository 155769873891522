<template>
  <div class="welcome-outer">
    <div class="welcome-header">
      The Teacher-pace mode is on now
    </div>
    <div class="welcome-content">
      <img src="@/assets/picture/teacher-page/modal-bg-teacher-welcome.png" class="welcome-img" />
      <ul class="welcome-content--text">
        <li class="welcome-info">• No matter you are teaching in a real classroom or hosting an online meeting, your projector will be displayed to your participants to engage them via the interactive content.</li>
        <li class="welcome-info">• You can open your dashboard or switch the teacher-paced to student-paced mode anytime by clicking the menu bar on the top right corner.</li>
      </ul>
    </div>
    <div class="welcome-footer">
      <el-checkbox v-model="isNotShowAgain" class="checkbox__show-again">Don't show this again</el-checkbox>
      <button @click="setIsModalVisible(false), callback()" class="invite-button got-it">I got it</button>
    </div>
  </div>
</template>

<script>
import {saveStore} from '@/utils/localStore'
import '@/assets/css/teacher.scss'

const storeKey = 'teacher-welcome-modal-setting'

export default {
  props: {
    setIsModalVisible: {
      type: Function,
    },
    callback: {
      type: Function,
    }
  },
  data() {
    return {
      isNotShowAgain: false,
    }
  },
  watch: {
    isNotShowAgain() {
      saveStore(storeKey, this.isNotShowAgain)
    },
  },
}
</script>

<style lang="scss" scoped>
.welcome-info {
  // position: absolute;
  word-break: normal;
}
:global(.checkbox__show-again) {
  color: #fd324f;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 1rem;
  }
}
:global(.el-dialog__wrapper) {
  overflow: hidden;
}
</style>
